<div sourceSense class="bar-section aggretator-header" barSection>
  <nav class="navbar " navbar>
      <div class="container">
          <div class="flex-row no-gutters justify-content-between align-items-center">
          <div class="col-auto" >
              <a class="navbar-brand  pad-right-0" href="javascript:; " routerLink="/"><img [src]="icon_logo">
                <!-- <h4 style="margin-top: 5px;" class=" text-white visible-xs visible-sm">PARKING IS JUST THE START<span
                  class="text-primary">.</span><sup>TM</sup></h4> -->
                  <h5 style=" float: right; margin-top:-21px;margin-left: 9em" class=" text-white ">PARKING IS JUST THE START<sup>TM</sup></h5>
              </a>
    </div>
    <div *ngIf="show" class="dropdown btn-outer-2">
      <a  [class.active]="isActivetoinside()" class="test" style="cursor:pointer;" class="dropdown-toggle" data-toggle="dropdown">{{ loggedUserName }} &nbsp; <fa name="angle-down"></fa></a>
      <ul class="dropdown-menu">
        <li><a class="text-primary" href="javascript:void();" (click)="logoutUser()">Sign Out <fa name="sign-out"></fa></a></li>
      </ul>
    </div>
      </div>
      </div>
  </nav>
 
</div>
