import { Injectable } from '@angular/core';

//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {HttpService} from './http.service';

import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(private http: HttpClient) { }

  private monthlyParkingReservationApi = CustomCode.APIURL + 'monthly-request/';
  private monthlyParkingReservationApiNew = CustomCode.APIURL + 'buy-monthly-parking/';
  private monthlyParkingReservationApiAggregator = CustomCode.APIURL + 'buy-monthly-parking-aggregator';
  private dailyParkingReservationApi = CustomCode.APIURL + 'user/reservation';

  private dailyParkingReservationGuestUserApi = CustomCode.APIURL + 'reservation';

  private getReservationDetails = CustomCode.APIURL + 'reservation-anon';
  private getReservationDetailsfornew = CustomCode.APIURL + 'reservation-anon';

  private getFacilityRateApi = CustomCode.APIURL + 'rate/facility';
  private updateReservationUrl = CustomCode.APIURL+'update-reservation-availability';
  private updateEventLogUrl = CustomCode.APIURL+'addUserEvent';

  reservationDetails(resId,id?) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getReservationDetails + '/' + resId).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        });
    });
  }
  reservationDetailsForNewImplementation(resId,id?) {
    if (localStorage.getItem('access_token')) {
      this.getReservationDetailsfornew = CustomCode.APIURL + 'loggedin-user-reservation-details';
    }else{
      this.getReservationDetailsfornew = CustomCode.APIURL + 'guest-reservation-details';
    }
    return new Promise((resolve, reject) => {
      this.http.get(this.getReservationDetailsfornew + '/' + resId+'/'+id).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        });
    });
  }
  reservationDetailsForDaily(resId, id?) {
    if (localStorage.getItem('access_token')) {
      this.getReservationDetailsfornew = CustomCode.APIURL + 'loggedin-user-reservation-details';
    } else {
      this.getReservationDetailsfornew = CustomCode.APIURL + 'guest-reservation-details';
    }
    return this.http.get(this.getReservationDetailsfornew + '/' + resId + '/' + id);
  }

  updateForNewImplementation(resId,id?) {
    this.getReservationDetails = CustomCode.APIURL + 'update-guest-reservation-flag';
    return new Promise((resolve, reject) => {
      this.http.get(this.getReservationDetails + '/' + resId+'/'+id).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        });
    });
  }
  getFacilityDetails(facId, arrivalTime, lengthOfStay, useBonus) {
    const options = CustomCode.returnHeaders();
    const finalUrl = this.getFacilityRateApi + '/' + facId + '?arrival_time=' + arrivalTime + '&length_of_stay=' + lengthOfStay + '&use_bonus=' + useBonus;
    return new Promise((resolve, reject) => {
      this.http.get(finalUrl).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  makeMonthlyReservation(userData, mRate,selectedvahicletype_Id, facId, encryptData, mDate, device_type) {
    //alert(userData.validationRequired);
    const monthlyRate = '';

        const options = CustomCode.returnHeaders();
        let keyOverSized='';
        let keyExotic='';
        let keyElectronic=''
        if(userData.key=='oversize_fee')
        {
          keyOverSized=userData.keyValue
          keyExotic=''
          keyElectronic=''
        }
        if(userData.key=='exotic_fee')
        {
          keyOverSized=''
          keyExotic=userData.keyValue
          keyElectronic=''
        }
        if(userData.key=='electric_fee')
        {
          keyOverSized=''
          keyExotic=''
          keyElectronic=userData.keyValue
        }
        let is_promocode_redeemed = false;
        if(userData.discountPrice > 0){
          is_promocode_redeemed = true;
        }else {
          userData.promocode=''
          is_promocode_redeemed = false;
        }
        const formDetails = {'first_name': userData.firstname,
        'last_name': userData.lastname,
        'email': userData.email,
        'phone': userData.phone,
        'company_name': userData.companyname,
        'address_1': userData.address1,
        'address_2': userData.address2,
        'city': userData.city,
        'state': userData.state,
        'zip_code': userData.zipcode,
        'pfirst_name': userData.pfirstname,
        'plast_name': userData.plastname,
        'pemail': userData.pemail,
        'pphone': userData.pphone,
        'pcompany_name': userData.pcompanyname,
        'paddress_1': userData.paddress1,
        'paddress_2': userData.paddress2,
        'pcity': userData.pcity,
        'pstate': userData.pstate,
        'vehicle_make': userData.vehiclemake,
        'vehicle_model': userData.vehiclemodel,
        'vehicle_color': userData.vehiclecolor,
        'vehicle_plate': userData.licenceplate,
        'additional_driver_1': userData.driver1,
        'additional_driver_2': userData.driver2,
        'additional_driver_3': userData.driver3,
        'desired_start_date' : mDate,
        'monthly_rate': userData.price_main,
        'monthly_rate_id' : selectedvahicletype_Id,
        'promocode': userData.promocode,
        'nonce' : encryptData,
        'facility_id' : facId,
        'make_id':userData.make_id,
        'model_id':userData.model_id,
        'monthly_duration_value':userData.monthly_duration_value,
        'description':userData.description,
        'oversize_fee':keyOverSized,
        'exotic_fee':keyExotic,
        'electric_fee':keyElectronic,
        'url_referral':userData.url_referral,
        'redeemed_amount':userData.discountPrice,
        'is_validation_required':userData.validationRequired,
        'is_promocode_redeemed' : is_promocode_redeemed,
        'processing_fees':userData.processing_fees,
        'device_type':device_type
        /* 'card': userData.card,
        'expiration_date': userData.expiration_date,
        'expiration_year': userData.expiration_year,
        'security_code': userData.security_code, */
      };
      if(userData.validationRequired=='')
      {
        delete formDetails.is_validation_required
        delete formDetails.promocode
      }
      if(userData.processing_fees==0)
      {
        delete formDetails.processing_fees
      }

        return this.http.post(this.monthlyParkingReservationApiNew + facId, formDetails, {headers:options})
          .map((res: any) => res)
          .catch((error: any) => Observable.throw(error));
      }

      makeMonthlyReservationForCampaign(userData, mRate, IsMonthlyCampaign, selectedvahicletype_Id, facId, encryptData, mDate) {
        const monthlyRate = '';
    
            const options = CustomCode.returnHeaders();
    
            const formDetails = {'first_name': userData.firstname,
            'last_name': userData.lastname,
            'email': userData.email,
            'phone': userData.phone,
            'company_name': userData.companyname,
            'address_1': userData.address1,
            'address_2': userData.address2,
            'city': userData.city,
            'state': userData.state,
            'zip_code': userData.zipcode,
            'vehicle_make': userData.vehiclemake,
            'vehicle_model': userData.vehiclemodel,
            'vehicle_color': userData.vehiclecolor,
            'licence_plate': userData.licenceplate,
            'additional_driver_1': userData.driver1,
            'additional_driver_2': userData.driver2,
            'additional_driver_3': userData.driver3,
            'desired_start_date' : mDate,
            'monthly_rate': mRate,
            'monthly_rate_id' : selectedvahicletype_Id,
            'nonce' : encryptData,
            'facility_id' : facId,
            'monthly_campaign' : IsMonthlyCampaign,
            'promocode': userData.promocode,
          };

        return this.http.post(this.monthlyParkingReservationApi + facId, formDetails, {headers:options})
          .map((res: any) => res)
          .catch((error: any) => Observable.throw(error));
      }

      makeDailyReservation(userData) {
            const options = CustomCode.returnHeaders();

            let useApi = this.dailyParkingReservationApi;
            const checkLogged = CustomCode.getLocalStorageData('username');
            if (checkLogged === null) {
              useApi = this.dailyParkingReservationGuestUserApi;
            }
            return this.http.post(useApi, userData, {headers:options})
              .map((res: any) => res)
              .catch((error: any) => Observable.throw(error));
          }

          paymentFromSaveCard(userData) {
            const options = CustomCode.returnHeaders();
            const useApi = this.dailyParkingReservationApi;

            return this.http.post(useApi, userData, {headers:options})
              .map((res: any) => res)
              .catch((error: any) => Observable.throw(error));
          }

          updateReservation(reservation_id, reservation_status){
            const options = CustomCode.returnHeaders();
            const reservation_status_data = {
              'reservation_id' : reservation_id,
              'type' : reservation_status
            }
            let updateUrl = this.updateReservationUrl;
            return this.http.post( updateUrl, reservation_status_data, {headers:options})
            .map((res:any) => res)
            .catch((error:any) => Observable.throw(error));
          }

          updateReservationEventLog(reservationEventData){
            const options = CustomCode.returnHeaders();
            let updateUrl = this.updateEventLogUrl;
            return this.http.post( updateUrl, reservationEventData, {headers:options})
            .map((res:any) => res)
            .catch((error:any) => Observable.throw(error));
          }


          makeMonthlyReservationAggregator(userData, mRate,selectedvahicletype_Id, facId, encryptData, mDate, device_type, enableAutoPay,surchargeValue) {
            //alert(userData.validationRequired);
            const monthlyRate = '';
        
                const options = CustomCode.returnHeaders();
                let headers = new HttpHeaders();
                const token = localStorage.getItem('access_token');
                const bigApple = localStorage.getItem('X-Big-Apple');
                headers.append('Authorization', 'Bearer ' + token);
                headers.append('X-Big-Apple',bigApple);
                headers.append('Accept','application/json');




                let keyOverSized='';
                let keyExotic='';
                let keyElectronic=''
                if(userData.key=='oversize_fee')
                {
                  keyOverSized=userData.keyValue
                  keyExotic=''
                  keyElectronic=''
                }
                if(userData.key=='exotic_fee')
                {
                  keyOverSized=''
                  keyExotic=userData.keyValue
                  keyElectronic=''
                }
                if(userData.key=='electric_fee')
                {
                  keyOverSized=''
                  keyExotic=''
                  keyElectronic=userData.keyValue
                }
                let is_promocode_redeemed = false;
                if(userData.discountPrice > 0){
                  is_promocode_redeemed = true;
                }else {
                  userData.promocode=''
                  is_promocode_redeemed = false;
                }
                const formDetails = {'first_name': userData.firstname,
                'last_name': userData.lastname,
                'email': userData.email,
                'phone': userData.phone,
                'company_name': userData.companyname,
                'address_1': userData.address1,
                'address_2': userData.address2,
                'city': userData.city,
                'state': userData.state,
                'zip_code': userData.zipcode,
                'pfirst_name': userData.pfirstname,
                'plast_name': userData.plastname,
                'pemail': userData.pemail,
                'pphone': userData.pphone,
                'pcompany_name': userData.pcompanyname,
                'paddress_1': userData.paddress1,
                'paddress_2': userData.paddress2,
                'pcity': userData.pcity,
                'pstate': userData.pstate,
                'vehicle_make': userData.vehiclemake,
                'vehicle_model': userData.vehiclemodel,
                'vehicle_color': userData.vehiclecolor,
                'vehicle_plate': userData.licenceplate,
                'additional_driver_1': userData.driver1,
                'additional_driver_2': userData.driver2,
                'additional_driver_3': userData.driver3,
                'desired_start_date' : mDate,
                'monthly_rate': userData.price_main,
                'monthly_rate_id' : selectedvahicletype_Id,
                'promocode': userData.promocode,
                'nonce' : encryptData,
                'facility_id' : facId,
                'make_id':userData.make_id,
                'model_id':userData.model_id,
                'monthly_duration_value':userData.monthly_duration_value,
                'description':userData.description,
                'oversize_fee':keyOverSized,
                'exotic_fee':keyExotic,
                'electric_fee':keyElectronic,
                'url_referral':userData.url_referral,
                'redeemed_amount':userData.discountPrice,
                'is_validation_required':userData.validationRequired,
                'is_promocode_redeemed' : is_promocode_redeemed,
                'processing_fees':userData.processing_fees,
                'device_type':device_type,
                'aggregator_name':userData.aggregator_name,
                'voucher_name':userData.voucher_name,
                'is_aggregator_booking':userData.is_aggregator_booking,
                'enableAutoPay' : enableAutoPay
              };
             
              if(userData.validationRequired=='')
              {
                delete formDetails.is_validation_required
                delete formDetails.promocode
              }
              if(userData.processing_fees==0)
              {
                delete formDetails.processing_fees
              }
              if(!enableAutoPay && !surchargeValue){
                delete formDetails.nonce
              }
        
                return this.http.post(this.monthlyParkingReservationApiAggregator +'/'+ facId, formDetails, { headers: new HttpHeaders({'Authorization': 'Bearer ' + token,'X-Big-Apple':bigApple,'Accept':'application/json'})})
                  .map((res: any) => res)
                  .catch((error: any) => Observable.throw(error.error));
              }

}
