import { Injectable } from '@angular/core';

//import { Http, Headers, RequestOptions, Response,ResponseContentType } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StaticpagesService {

  constructor(private http: HttpClient) { }

  private aboutUs = CustomCode.APIURL + 'cms/slug';

  private contactUsApiUrl = CustomCode.APIURL + 'correspondence';

  private monthlyParkingInformationApi = CustomCode.APIURL + 'basicmonthlyrequest';

  private cmiApiUrl = CustomCode.cmsUrl + 'cms/api_pages_data_slug.php?slug=';

  private CampaignsApiUrl = CustomCode.cmsUrl + 'cms/api_monthly-campaign_slug.php';
  private cmiApiUrlForHotels = CustomCode.cmsUrl + 'cms/api_pages_data_slug_hotels.php?slug=';
  private cmiApiUrlForspecials = CustomCode.cmsUrl + 'cms/api_pages_data_slug_landing.php?slug=';
  private NeighbourhoodListUrl = CustomCode.APIURL + 'monthly-campaign-neighborhood';
  private B2BbusinessFormUrl = CustomCode.APIURL + 'free-quotes-inquiry';
  private CMSContentFieldsUrl = CustomCode.cmsUrl + 'cms/wp-json/wp/v2/pages/2380';
  private cmiApiUrlForspecialsMoovit = CustomCode.cmsUrl + 'cms/api_pages_data_slug_landing-all.php?slug=';
 private CmsApiUrl = CustomCode.cmsUrl + 'cms/wp-json/wp/v2/pages/';
//  https://staging2.iconparkingsystems.com/cms/wp-json/wp/v2/pages/


HeaderForCMSAPIGet(){
  const currentAccessToken = CustomCode.getLocalStorageData('access_token');
  let authorizeBearer='';
  // if (currentAccessToken) {
  //     authorizeBearer = 'Bearer ' + currentAccessToken;
  // } else { authorizeBearer = ' '; }    
  const headers = new HttpHeaders();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', authorizeBearer);
  //const options = new RequestOptions({ headers: headers });
  return { headers: headers };
}

  staticPagesContent(pageName) {
    return new Promise((resolve, reject) => {
      this.http.get(this.aboutUs + '/' + pageName).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        });
    });
  }

  getCampaignNeighbourhood(id?){
    if(id==1)
    {  
    }else{
     id=0
    }
    return this.http.get(this.NeighbourhoodListUrl+'/'+id).map(
      (res:Response) => res
     ).retry(5);
    }

    getMonthlyCampaignContent( ){
            return this.http.get(this.CampaignsApiUrl).map(
              (res:Response) => res
            ).retry(5);
          }

  contactUs(userData) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'name': userData.name, 'email': userData.email,
    'phone': userData.phone, 'city': userData.city, 'state': userData.state, 'comments': userData.comments};
    return this.http.post(this.contactUsApiUrl, formDetails, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  B2BbusinessForm(userData) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'first_name': userData.first_name,'last_name': userData.last_name, 'email': userData.email,
    'company_name': userData.phone, number_of_employees : userData.number_of_employees};
    return this.http.post(this.B2BbusinessFormUrl, userData, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }


  
  submitMonthlyParkingInformation(userData) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'full_name': userData.name, 'email': userData.email, 'phone': userData.phone, 'preferred_location': userData.preffred_location,
    'preferred_communication': userData.prefcomm, 'vehicle_model': userData.vehicle_model,
    'vehicle_make': userData.vehicle_make, 'current_customer': userData.monthly_customer,
    'desired_area': userData.desire_parking_area};
    return this.http.post(this.monthlyParkingInformationApi, formDetails, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  fetchFacilityRates(url) {
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + url).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }



  getCmsPageContent( pageId ){
    return this.http.get(this.cmiApiUrl+pageId, this.HeaderForCMSAPIGet()).map(
      (res:Response) => res
    ).retry(5);
  }
  getCmsPageContentForHotels( pageId ){
    return this.http.get(this.cmiApiUrlForHotels+pageId).map(
      (res:Response) => res
    ).retry(5);
  }
  getCMSpageContentList(pageId){
    return this.http.get( this.cmiApiUrlForspecials + pageId).map(
      res => res
    ).retry(5);
  }


  getCMSpageContentWithFields(pageSlug){
    let slug = '?slug='+pageSlug;
    return this.http.get( this.CmsApiUrl+slug ,this.HeaderForCMSAPIGet()).map(
      res => res
    ).retry(5);
  }
}
