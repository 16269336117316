<div class="modal fade login-modal" id="login">
    <div class="modalWrapper">
    <div class="modal-dialog signBgTo">
        <div class="pad-top-sm" *isIphone></div>
        <div class="panel panel-default mar-bottom-0">
            <button type="button" (click)="reset(formData, f)" class="close" data-dismiss="modal" aria-hidden="true"><fa name="times"></fa></button>
            <form class="p normallogin" name="form" role="form" id="floating-label-login-comp1" (ngSubmit)="f.form.valid && userSignIn(formData, f)" #f="ngForm"
                novalidate>
                <div class="text-center" id="errors-container"></div>
                <h2 class="text-primary text-uppercase mar-top-0 mar-bottom-sx" [ngClass]="{ 'mar-bottom-sx' : showMonthlyRedirectionMsg, 'mar-bottom-sm' : !showMonthlyRedirectionMsg}">Sign In</h2>
                <h5 class="mar-top-0 mar-bottom-sm" *ngIf="showMonthlyRedirectionMsg">You must create a new account or sign in to your existing account to proceed.</h5>
                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !email.valid}">
                    <label class="control-label" for="email">Email Address</label>
                    <input class="form-control" type="text" name="email" [(ngModel)]="formData.email" #email="ngModel" required pattern="{{emailRegex}}"
                    />
                    <div *ngIf="f.submitted && !email.valid" class="help-block">Email is required</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !password.valid}">
                    <label class="control-label" for="password">Password</label>
                    <input class="form-control" type="password" name="password" [(ngModel)]="formData.password" #password="ngModel" required validateEqual="cpassword"
                    />
                    <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div class="checkbox mar-top-0 mar-bottom-0">
                            <label class="">
                                <input type="checkbox" value="">
                                Remember Me
                            </label>
                        </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6  text-right">
                            <a (click)="navigateresetPassword()" class="btn-link forget-btn">Forgot Password? &nbsp; &nbsp; &nbsp; <fa name="chevron-right"></fa></a>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary btn-fill btn-block text-uppercase">sign in</button>
                </div>
                <div class="clearfix devider" *ngIf="isLandingPage !== true">
                    <hr><span class="or">OR</span>
                </div>
                <div class="socialLogin text-center">
                    <div class="clearfix">
                        <div class="row">
                            <div class="col-xs-12">
                                <a href="javascript:;" class="btn socialBtn fb" (click)="fblogin()">
                                    <img [src]="fbBtnIcon" alt="image">Sign in with Facebook
                                </a>
                            </div>
                            <div class="col-xs-12">
                                <google-signin></google-signin>
                            </div>
                            <div class="col-xs-12" *ngIf="showButton">
                                <div id="appleid-signin" data-color="black" data-border="true" data-type="sign in"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix">
                    <a class="btn-link login-link1" (click)="redirectToSignUp()"><span class="pull-left">Don't have an account? Click here to create one &nbsp;</span><fa name="chevron-right" class="pull-right"></fa></a>
                    <a [href]="redirect" class="btn socialBtn fb" #myDiv></a>
                </div>
            </form>
            <div class="fblogin floating-label hide">
                <div class="text-center" id="errors-container"></div>
                <h2 class="text-primary text-uppercase mar-top-0 mar-bottom-sm">Enter Your Email</h2>
                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !email.valid}">
                    <label class="control-label" for="email">Email Address</label>
                    <input class="form-control" type="text" id="emailfb" />
                    <div id="validEmail" class="hide" style="color:red">Email is required</div>
                </div>
                <button (click)="fbloginafter();" class="btn btn-primary btn-fill btn-block text-uppercase">sign in</button>
            </div>
        </div>
    </div>
</div>
</div>


