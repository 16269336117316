import { Injectable } from '@angular/core';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class PromocodeCmsService {
	constructor(private http: HttpClient) { }
	private getUsersDetails = CustomCode.APIURL + 'api';
	private getResetUserEmail = CustomCode.APIURL + 'user/';

  fetchContent(slug_name) {
   return  this.http.get(CustomCode.imageUrl+'cms/promotion_template.php?temp_slug='+slug_name).map((res: any) => res)
  }
  checkPartner(partner) {
  	var partnerName={'name':partner}
	return  this.http.post(CustomCode.APIURL+'create-company-affilate',partnerName).map((res: any) => res)
  }
}

