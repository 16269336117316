<app-headeragrigator></app-headeragrigator>
  <!-- Coupons Redemptions -->

  <!-- <div class="page-header" id="CouponsRedemptions"></div> -->
  <div class="CouponsRedemptions">
    <div class="container">
    <!-- <app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load> -->
     <div class="Redemptions-popup white-box-sdw">
      <h3 class="text-center mar-top-0 mb-3" sourceSense>Welcome to Icon Parking</h3>
      <p class="text-center mb-0" sourceSense>Enter your Voucher Number below</p>
       <form (ngSubmit)="f.form.valid && getVoucher(f.form.value)" #f="ngForm" novalidate>
        <div class="row">

          <div class="col-sm-12 col-md-12">

              <div class="form-group mar-bottom-xs">

                  <input id="voucher-num" class="form-control inputBoxStyling" ngModel #voucher="ngModel" type="text" autocomplete="off" name="voucher" placeholder="Type your voucher number" [required]="true" />

                  <div class="help-block help2" *ngIf="f.submitted && !voucher.valid">Voucher number is required</div>

              </div>

          </div>
          <div class="col-sm-12 col-md-12 text-center">
            <button type="submit" style="min-width: 200px; min-height: 40px;" class="btn btn-primary btn-fill px-5 d-inline-block mar-top-xs">SUBMIT</button>
          </div>

      </div>
       </form>
     </div>
     <!-- <p class="text-center mar-top-sm">
       <img src="../../../../assets/img/SpotHero_Logo.png" width="60" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src="../../../../assets/img/park-whiz.png" width="140"></p> -->

    </div>
  </div>

<app-footer></app-footer>