import { Injectable } from '@angular/core';
// import { AppModule } from ''
//import { Http, Headers, RequestOptions, Response, Request, RequestMethod, ResponseContentType } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import { ToastrService } from 'ngx-toastr';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserserviceService {
 // headers = new HttpHeaders();
  constructor(private http: HttpClient, toastr?: ToastrService, router?: Router) { 
    //const token = localStorage.getItem('access_token');
    //const bigApple = localStorage.getItem('X-Big-Apple');
    //this.headers.set('Authorization', 'Bearer ' + token);
    //this.headers.set('X-Big-Apple',bigApple);
  }

  private addUser = CustomCode.APIURL + 'user';
  private loginUrl = CustomCode.APIURL + 'oauth/access_token';
  private loginSocialUrl = CustomCode.APIURL + 'user/social';
  private loginCheckApi=CustomCode.APIURL + 'check-social-user-email';

  // Monthly Parking Internal Pages API URL's
  private getUsersDetails = CustomCode.APIURL + 'api';
  private getUsersPromocodeDetails = CustomCode.APIURL + 'userPromoUsage';
  private addNewPromoCode = CustomCode.APIURL + 'adduserpromocode';
  private deleteUserPromoCode = CustomCode.APIURL + 'deleteUserPromoCode';
  private getUsersMonthlyAccountURL = CustomCode.APIURL + 'monthly-parking/account/';
  private monthlyParkingRegisterURL = CustomCode.APIURL + 'monthly-parking/register/';
  private getVisitorCodeURL = CustomCode.APIURL + 'visitor/';
  private decryptURL = CustomCode.APIURL + 'decrypt/user';
  private regLoyaltyURL = CustomCode.APIURL + 'loyalty/register';
  private getWalletURL = CustomCode.APIURL + 'user/wallets';
  private getWalletConfigURL = CustomCode.APIURL + 'wallet/configs';
  private getProfileURL = CustomCode.APIURL + 'loyalty';
  private updateProfileURL = CustomCode.APIURL + 'loyalty/update-profile';
  private getCardUrl = CustomCode.APIURL + 'loyalty/cards';
  private getCardUrlDetails = CustomCode.APIURL + 'loyalty/card';
  private addCardUrl = CustomCode.APIURL + 'loyalty/card';
  private getmantinanceMgsUrl = CustomCode.APIURL+ 'web-notifications';
  private getLoyatlyHistoryUrl = CustomCode.APIURL+ 'loyalty/history';
  private emailExistUrl = CustomCode.APIURL+ 'validate-user';
  private deleteCard=CustomCode.APIURL+ 'user/authorizenet/payment';
  private updateUserProfileURL = CustomCode.APIURL+ 'update-profile';
  private addCorporateUserURL = CustomCode.APIURL+ 'add-referred-corporate-user';
  private getCorporatePartnerURL = CustomCode.APIURL+ 'corporate-partner';
  private getTierInfoURL = CustomCode.APIURL + 'loyalty/get-user-tier';
  private loginSocialUrlapple = CustomCode.APIURL + 'user/social-apple';
  private versionUrl = CustomCode.APIURL + 'mobile-device-version/web';

  registerUser(userData) {
    
    const options = CustomCode.returnHeaders();
    
    const formDetails = {'username':userData.email,'grant_type': CustomCode.loginGrantType, 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'name': userData.name, 'email': userData.email, 'password': userData.password, 'phone': userData.phone};

    return this.http.post(this.addUser, formDetails, {headers:options});
      //.map((res: Response) => res)
      //.catch((error: any) => Observable.throw(error));
  }

  registerLoyaltyUser(userData) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'iq_code':userData.iq_code,'username':userData.email,'grant_type': CustomCode.loginGrantType, 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'name': userData.name, 'email': userData.email, 'password': userData.password, 'phone': userData.phone, 'is_loyalty': userData.is_loyalty,'invitation_code': userData.invitation_code};
    return this.http.post(this.addUser, formDetails, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  LoyaltyUpdateProfile(userData) {
    const options = CustomCode.returnHeaders();
    return this.http.put(this.updateProfileURL, userData, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  LoyaltyAddCard(userData) {
    const cardHeader = CustomCode.returnHeaders();
    return this.http.post(this.addCardUrl, userData, {headers:cardHeader})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }
  
  LoyaltyGetCards() {
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getCardUrl, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  LoyaltyGetCard(cardId) {
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getCardUrlDetails+"/"+cardId, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  LoyaltyRemvoeCard(cardId){
    const options = CustomCode.returnHeaders();
    return this.http.delete(this.getCardUrlDetails+"/"+cardId, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  EncrypInformation(encrptedData) {
    const options = CustomCode.returnHeaders();
    return this.http.post(this.decryptURL, encrptedData, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  RegisterToLoyalty(userData) {
    const options = CustomCode.returnHeaders();
    return this.http.put(this.regLoyaltyURL, userData, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  isEmailExist(userData) {
    const options = CustomCode.returnHeaders();
    return this.http.post(this.emailExistUrl, userData, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  GetLoyaltyWallet(){
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getWalletURL, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  GetLoyaltyWalletConfig(){
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getWalletConfigURL, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  GetLoyaltyProfile(){
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getProfileURL, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }
  
  GetLoyaltyHistory(){
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getLoyatlyHistoryUrl, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  handleSessionRefresh() {
    const refTokenData = {
      'grant_type': 'refresh_token', 
      'client_id': CustomCode.clientId,
      'client_secret': CustomCode.clientSecret, 
      'refresh_token': localStorage.getItem('refresh_token'), 
      'remember_me': null
    };
    this.refreshToken(refTokenData)
      .subscribe(
      data => {
        let responseData = data.data;
        localStorage.setItem('user', responseData.user);
        localStorage.setItem('access_token', responseData.access_token);
        localStorage.setItem('refresh_token', responseData.refresh_token);
        localStorage.setItem('expires_in', responseData.expires_in);
        localStorage.setItem('token_type', responseData.token_type);
        window.location.reload();
      },
      error => {
        return false;
      }
    );
  }

  refreshToken(data) {
    return this.http.post(this.loginUrl, data)
    .map(
      (res: any) => res
    )
    .catch(
      (error: any) => Observable.throw(
        error
      )
    );
  }

  loginUser(userData) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'grant_type': CustomCode.loginGrantType, 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'username': userData.email, 'password': userData.password};

    return this.http.post(this.loginUrl, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }
  loginUserWithUserId(userData) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'grant_type':'user_id_login', 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'username': userData.id, 'password': ''};

    return this.http.post(this.loginUrl, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }
  loginSocialUser(userData) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'for_register':userData.for_register,'social_id':userData.social_id, 'social_type':userData.social_type, 'grant_type': CustomCode.loginGrantTypeSocial, 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'email': userData.email,'name':userData.name};

    return this.http.post(this.loginSocialUrl, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }
  loginCheckSocialUser(userData) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'social_id':userData, 'social_type':0};

    return this.http.post(this.loginCheckApi, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }
  fetchUser() {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersDetails).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  fetchMyPromoCodes(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getUsersPromocodeDetails, data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  fetchWalletConfig() {
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'icongo/walletconfig').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  fetchUserCoupons(userId) {
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'user/' + userId + '/coupon/page/1').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  fetchUserSavedCards() {
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'user/authorizenet/cim').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  fetchNextPrevCoupons(userId, pageNo) {
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'user/' + userId + '/coupon/page/' + pageNo).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  fetchUserReservations(userId) {
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'user/' + userId + '/reservation/page/1').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  fetchNextPrevReservations(userId, url) {
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'user/' + userId + '/reservation/page/' + url).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  cancelReservation(id) {
    return new Promise((resolve, reject) => {
      this.http.post(CustomCode.APIURL + 'reservation/' + id + '/cancel', {}).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }
  downloadPaymentReceipts(id,account)
  {
    const headers = new Headers();
    const access_token = localStorage.getItem('access_token');
    headers.append('Authorization', 'Bearer ' + access_token);
    
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'monthly-parking/account/' + account + '/' +id+ '/payment/pdf', { responseType: 'blob',headers: new HttpHeaders({'Authorization': 'Bearer ' + access_token})}).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  printReservation(id) {
    const headers = new Headers();
    const access_token = localStorage.getItem('access_token');
    headers.append('Authorization', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'reservation/' + id + '/jpg', { responseType: 'blob',headers: new HttpHeaders({'Authorization': 'Bearer ' + access_token})}).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
    // return new Promise((resolve, reject) => {
    //   this.http.get(CustomCode.APIURL + 'reservation/' + id + '/jpg').subscribe(
    //     res => {
    //       resolve(res);
    //     },
    //     error => {
    //       reject(JSON.parse(error['_body']));
    //     }
    //   );
    // });
  }
  
  printReservationByEmail(resCode,ticketechCode){
    const headers = new Headers();
    const access_token = localStorage.getItem('access_token');
    //headers.append('Authorization', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
	//https://test-api.iconparkingsystems.com/reservation/19c87f0f864f6fc/IQ216853/jpg
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'reservation/' + resCode + '/' + ticketechCode + '/jpg', { responseType: 'blob',headers: new HttpHeaders({'Authorization': 'Bearer ' + access_token})}).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  
   fetchUserReservationsByCode(canResCode,canTicketechCode) {
	   //https://test-api.iconparkingsystems.com/reservation/19c87f0f864f6fc/IQ216853
	   //CustomCode.APIURL + 'reservation/' + canResCode + '/'+canTicketechCode
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'reservation/' + canResCode + '/'+canTicketechCode).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }
  
  cancelReservationByEmail(resCode,ticketechCode,reservationUserDetailsid){
	  ///reservation/cancel/19c87f0f864f6fc/IQ216853
    return new Promise((resolve, reject) => {
      this.http.post(CustomCode.APIURL + 'reservation/cancel/' + resCode + '/'+ticketechCode, reservationUserDetailsid).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  userReferral(data) {
    return new Promise((resolve, reject) => {
      this.http.post(CustomCode.APIURL + 'user/getreferralcode', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  fetchUserWallet(userId) {
    let data = { 'user_id' : userId };
    return new Promise((resolve, reject) => {
      this.http.post(CustomCode.APIURL + 'user/mywallet', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  deleteUserCoupon(couponId) {
    return new Promise((resolve, reject) => {
      this.http.delete(CustomCode.APIURL + 'user/coupon/' + couponId).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }
  deleteUserCard(id) {
    //let data ={"payment_profile_id": id}
    return this.http.delete(CustomCode.APIURL + 'user/authorizenet/payment?payment_profile_id='+id)
   .map((res: any) => res)
   .catch((error: any) => Observable.throw(error));

  }

  addPromoCode(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.addNewPromoCode, data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  deletePromoCode(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.deleteUserPromoCode, data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  validatePromoCode(data) {
    return new Promise((resolve, reject) => {
      this.http.post(CustomCode.APIURL + 'checkpromo', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  getAccountDetails(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  monthlyParkingRegister(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.monthlyParkingRegisterURL, data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  autoPay(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/autopay').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  deleteAccount(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.getUsersMonthlyAccountURL + accountNumber).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  addCreditCard(data, accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getUsersMonthlyAccountURL + accountNumber + '/payment-method', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  addBankAccount(data, accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getUsersMonthlyAccountURL + accountNumber + '/payment-method', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  currentInvoice(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/current-invoice').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  previousInvoices(accountNumber, pageNum) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/past-invoice?page=' + pageNum).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  getVisitorCode(){
    const options = CustomCode.returnHeaders();

     return this.http.post(CustomCode.APIURL+'visitor',  {})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }

  getVisitorCouponTracked(data)
  {  
     var couponId = data['couponId'];
     const options = CustomCode.returnHeaders();

     return this.http.post(CustomCode.APIURL+'visitor/coupon/'+couponId,  data)
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));

  }

  getReferrerForVisitor()
  {
    let referrerUrl = localStorage.getItem('referrerUrl');
    let data =  {'referrer' :referrerUrl };             
    return this.http.post(CustomCode.APIURL+'visitor/referral', data)
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }
  
  
   getMantinanceMsg(){
    return this.http.get(this.getmantinanceMgsUrl)
    .map( (res:any)=> res )
    .catch((error:any)=> Observable.throw(error) );
  }

  updateProfile(userData){
    const options = CustomCode.returnHeaders();
    return this.http.put(this.updateUserProfileURL, userData, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  AddCorporateUser(userData){
    const options = CustomCode.returnHeaders();
    return this.http.post(this.addCorporateUserURL, userData, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  getCorporatePartner(userData){
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getCorporatePartnerURL+'/'+userData)
    .map( (res:any)=> res )
    .catch((error:any)=> Observable.throw(error) );
  }

  GetTierInfo(){
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getTierInfoURL, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  loginSocialUserapple(userData) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'for_register':userData.for_register,'social_id':userData.social_id, 'social_type':userData.social_type, 'grant_type': CustomCode.loginGrantTypeSocial, 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'email': userData.email,'name':userData.name};

    return this.http.post(this.loginSocialUrlapple, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }
  checkVersion()
  {
    const options = CustomCode.returnHeaders();
    return this.http.get(this.versionUrl, {headers:options})
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error));
  }

  validatePromoCodeMonthly(data) {
    return new Promise((resolve, reject) => {
      this.http.post(CustomCode.APIURL + 'checkpromo-monthly', data).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }

  
}

