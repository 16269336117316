
import { Component, OnInit,AfterViewInit,ElementRef,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CustomCode } from '../shared/CustomCode';
import { FormValidationErrors } from '../shared/formValidationErrors';
import * as $ from 'jquery';
declare var google: any;
import { UserserviceService } from '../services/userservice.service';
import { MyAccountMonthlyParkingService } from '../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from './login.component';
declare const gapi: any;

@Component({
  selector: 'google-signin',
  template: '<a href= "javascript:;" class= "btn socialBtn gplus"><img src="assets/img/gplus-btn.png" alt="icon" style="height: 18px;margin-right: 20px;">Sign in with Google</a>',
  styles: ['.socialBtn { text-transform:uppercase; width: 100%; height: 40px; line-height: 32px; margin-bottom: 15px; display: inline-block; border: 0px;background-color:#dd4b39;color:#fff;padding-left: 6px;padding-right: 10px;}']
})
export class GoogleSigninComponent implements AfterViewInit {

  private clientId: string = CustomCode.googleApi;

  private scope = [
    'profile',
    'email'
  ].join(' ');
  public auth2: any;
  private respdata;
  private parseData;
  public currentRoute = '';
  facilityRadius = CustomCode.facilityDefaultRadius;
  userDetails;
  promoCode;
  email:any;
  isLandingPage:boolean = false;

  loginForm: NgForm;
  bigApple;

  emailRegex = CustomCode.emailRegex;
  landingLocations:any = {};

  currDate = CustomCode.getDateForMapSearch();
  public googleInit() {

    let that = this;
    gapi.load('auth2', function () {
      that.auth2 = gapi.auth2.init({
        client_id: that.clientId,
        cookiepolicy: 'single_host_origin',
        scope: that.scope
      });
      that.attachSignin(that.element.nativeElement.firstChild);

    });
  }
  public attachSignin(element) {
    let that = this;

    this.auth2.attachClickHandler(element, {},
      function (googleUser) {

        let profile = googleUser.getBasicProfile();
       
        let formData = {'name':profile.getName(), 'social_type':'1','social_id':profile.getId(),'email':profile.getEmail(),'picture':profile.getImageUrl()}
        localStorage.setItem('bygmail', JSON.stringify(formData))
        localStorage.setItem('loginwithgm','1');

      }, function (error) {
      });
      
  }

  constructor(private element: ElementRef,private router: Router,
    private routerObj: Router, 
      private userServiceObj: UserserviceService,
      private activatedRouteObj: ActivatedRoute,
      private toasterObj: ToastrService,
      private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService) {
  }

  ngAfterViewInit() {
    this.googleInit();
  }
  ngOnInit()
    {
            setInterval(() => {
                 //console.log(localStorage.getItem('bygmail'));
                 if (localStorage.getItem('bygmail') && localStorage.getItem('bygmail') != 'undefined') {
                     let formData = JSON.parse(localStorage.getItem('bygmail'));
                     localStorage.removeItem('bygmail');
                     //console.log("hello");
                     this.userSignInWithSocial(formData)
                  }
                }, 100);
   }
    userSignInWithSocial(formValues) {
   
    this.userServiceObj.loginSocialUser(formValues)
      .subscribe(
        data => {
          this.parseData = data;
          if (this.parseData.status === 201) {
            CustomCode.markSocilaLoginUser(this.parseData.data);
            localStorage.setItem('user_login_type', 'Google');
            const userLat = CustomCode.timeSquareCoords.lat;
            const userLong = CustomCode.timeSquareCoords.long;
            const userLocation = CustomCode.timeSquareCoords.defaultLocation;
            if(!localStorage.getItem('monthlyParkingId'))
            {
              $('button.close').trigger('click');
            }
            if(0)
          {
              $('button.close').trigger('click');
              localStorage.setItem('is_admin','true');
              window.location.href=CustomCode.adminUrlLogin+'/redirectto/'+localStorage.getItem('access_token')+'/'+localStorage.getItem('refresh_token')+'/0'
            }else{
            const userLat = CustomCode.timeSquareCoords.lat;
            const userLong = CustomCode.timeSquareCoords.long;
            const userLocation = CustomCode.timeSquareCoords.defaultLocation;
            if(!localStorage.getItem('monthlyParkingId'))
            {
              $('button.close').trigger('click');
            }
            // if ((this.routerObj.url.indexOf('search') > -1 && this.routerObj.url.indexOf('monthly') > -1) || localStorage.getItem('clickBuyMonthly') ) {
            //   $('#loginPopup').hide();
            //   $('#loggeduser').show();
            //  // console.log(localStorage.getItem('monthlyParkingId')+'11111111111111111111111111111111111')
            //   if(localStorage.getItem('monthlyParkingId'))
            //   {
            //     let routeNavigate = '/buy-monthly';
            //     let facId=localStorage.getItem('monthlyParkingId')
            //     let fDate=localStorage.getItem('monthlyParkingDate')
            //     localStorage.removeItem('monthlyParkingId')
            //     localStorage.removeItem('monthlyParkingDate')
            //     localStorage.removeItem('clickBuyMonthly');
            //     //console.log('1111111111111111111');
            //     this.routerObj.navigate([routeNavigate, facId, fDate]);
            //   }
            // $('button.close').trigger('click');
            // }

            // $('button.close').trigger('click');
          if (this.routerObj.url.indexOf('buy-monthly') > -1 || this.routerObj.url.indexOf('aggregator-monthly-booking') > -1 ) {
            $('#loginPopup').hide();
            $('#guestuser').hide();
            $('#loggeduser').show();
            localStorage.setItem('anotherLogin', '1');
           // console.log(localStorage.getItem('monthlyParkingId')+'11111111111111111111111111111111111')
          $('button.close').trigger('click');
          }
            
            else if (this.routerObj.url.indexOf('payment') > -1 || this.routerObj.url.indexOf('buy-monthly') > -1) {
              window.location.reload();
            } else {
              if (localStorage.getItem('is_landing_page') || this.routerObj.url.indexOf('offer') > -1 ) {
  
                let action_type = localStorage.getItem('action_type');
                let promocode = localStorage.getItem('promocode');
                this.promoCode = promocode
                let routeFullurl =localStorage.getItem('routeUrl');
                let promotion_id = localStorage.getItem('promotion_id');
                let template_slug = localStorage.getItem('template_slug');
                let landing_page_url = "/"+routeFullurl+"/" + template_slug + "/" + promotion_id + "/" + promocode + "/" + action_type;
                if (1) {
                  if (localStorage.getItem('save')) {
                    if (action_type == 'save' && this.routerObj.url.indexOf(routeFullurl) > -1) {
                      this.fetchUserDetails();
                    } else {
                      if(localStorage.getItem('savebylogin')=='true')
                      {
                        this.fetchUserDetails();
                      }else{
                        window.location.reload();
                      }
                      
                    }
                  } else {
                    window.location.reload();
                  }
                }
              }else if(this.routerObj.url.indexOf('icon-rewards-register') > -1 && localStorage.getItem('is_loyalty') == '1'){
                if(this.parseData.data.user.is_loyalty_active){
                  this.routerObj.navigate(['my-account/manage-icon-rewards-details']);
                }else{
                  this.routerObj.navigate(['my-account/manage-icon-rewards-card']);
                }
              } else {
                if(sessionStorage.getItem('email_ref'))
                {
                  sessionStorage.removeItem('email_ref');
                  this.routerObj.navigate(['/my-account/icon-rewards']);
                }else{
                  if(this.parseData.data.user.is_loyalty == 1){
                    if(this.parseData.data.user.is_loyalty_active == 1){
                      this.routerObj.navigate(['my-account/account']);
                      return false;
                      // if(this.parseData.data.user.is_loyalty_profile_completed == 1){
                      //   this.routerObj.navigate(['my-account/account']);
                      //   return false;
                      // }else{
                      //   this.routerObj.navigate(['my-account/manage-icon-rewards-details']);
                      //   return false;
                      // }
                    }else{
                      this.routerObj.navigate(['my-account/manage-icon-rewards-card']);
                      return false;
                    }
                  }
                  this.routerObj.navigate(['/my-account/account']);
                }
              }
              // this.routerObj.navigate(['/search', userLat, userLong, this.currDate,
              // this.facilityRadius, userLocation, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
            }
          }
          }
        },
      error => {
        //FormValidationErrors.displayErrors(error.errors);
        const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
        } else {
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!');
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
              }
            }
          }
        }
      }
      );
  }
  fetchUserDetails() {
    this.myAccountMonthlyParkingObj.fetchUser()
    .then(
      data => {
        this.userDetails = data['data'];
        this.validatePromoCode('save');
      },
      error => {
      }
    );
  }

  validatePromoCode(mode) {
    let data = {
        'promocode': this.promoCode,
        'user_id' : this.userDetails.id
      };
    this.userServiceObj.validatePromoCode(data)
    .then(
      data => {
        if (data['data'].is_promocode_valid) {
          this.savePromocode();
        } else {
          this.toasterObj.error('Promocode has been expired', 'Sorry!');
          window.location.reload();
        }
      },
      error => {
        if (error.errors.message == "Please log in or create an account to redeem this promo code.") {
          this.toasterObj.error('Please login to your existing account or create a new one in order to save and redeem this promotion.', 'Sorry!');
        } else {
          this.toasterObj.error(error.errors.message, 'Sorry!');
          window.location.reload();
        }
      }
    );
  }

  savePromocode() {
    const data = {'promocode' : this.promoCode, 'user_id' : this.userDetails.id};
    this.userServiceObj.addPromoCode(data)
    .then(
      data => {
       
        if (!data['errors']) {
            this.toasterObj.success('Promocode successfully added, Please make a reservation to redeem this offer.', 'Great!');
            this.navidateToSearch();
        } else {
          this.toasterObj.error('Something wrong happened, try again later', 'Sorry!');
          window.location.reload();
        }
      },
      error => {
        // this.toasterObj.error(error.errors.message, 'Sorry!');
        this.navidateToSearch();
      }
    );
  }

  navidateToSearch() {
    let location = "times-square";
    if (localStorage.getItem('neighborhood')) {
      location = localStorage.getItem('neighborhood');
    }
    if (this.landingLocations[location]) {
      if (this.landingLocations[location].type == "location") {
        this.navigateToLocation(this.landingLocations[location]);
      } else {
        this.navigateToNeighborhood(this.landingLocations[location]);
      }
    } else {
      this.navigateToLocation(this.landingLocations['default']);
    }
  }

  navigateToLocation(details) {
      this.routerObj.navigate([
          '/search', 
          details.lat, 
          details.long, 
          CustomCode.getDateForMapSearch(), 
          CustomCode.facilityDefaultRadius, 
          details.label, 
          CustomCode.defaultFacilityType, 
          CustomCode.searchMode()
      ]);
    }

    navigateToNeighborhood(details) {
      this.routerObj.navigate(['/neighborhoods/', details.slug]);
    }

  showUserLogin() {

  }

  redirectToSignUp() {
    $('button.close').trigger('click');
    this.routerObj.navigate(['/create-account']);
  }
  
  navigateresetPassword() {
      this.routerObj.navigate(['/reset-password']);
      $('button.close').trigger('click');
      $('.sidenav').removeClass('open');
      $('body').removeClass('noscroll');
      $('.sidenavBackDrop').removeClass('open');
    }

  continueAsGuest() {
    $('button.close').trigger('click');
    const userLat = CustomCode.timeSquareCoords.lat;
    const userLong = CustomCode.timeSquareCoords.long;
    const locationUser = CustomCode.timeSquareCoords.defaultLocation;
    this.routerObj.navigate(['/search', userLat, userLong, this.currDate, this.facilityRadius, locationUser, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
  }
  getVisitorCode() {
   this.userServiceObj.getVisitorCode()
      .subscribe(
      data => {
        // var test = JSON.parse(data);
        
         localStorage.setItem('X-Big-Apple', data.data.visitor_code);
       },
      error => {
        let errorMessage = error.errors.message;
      }
    );
  }
  initializeLocationData() {
      this.landingLocations['default'] = {
        'lat': CustomCode.timeSquareCoords.lat,
        'long': CustomCode.timeSquareCoords.long,
        'label': CustomCode.timeSquareCoords.defaultLocation,
        'type' : 'location'
      };

      this.landingLocations['all-nbh'] = {
        'slug': "central-park",
        'type' : 'neighborhood'
      };

      this.landingLocations['bryant-park'] = {
        'lat': 40.753597,
        'long': -73.983233,
        'label': "Bryant Park Winter Village, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['rockefeller'] = {
        'lat': 40.758701,
        'long': -73.978595,
        'label': "Rockefeller Center Christmas Tree, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['5th-ave'] = {
        'lat': 40.758074,
        'long': -73.977059,
        'label': "5th Ave Window Displays, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['union-square'] = {
        'lat': 40.735863,
        'long': -73.991084,
        'label': "Union Square, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['madison-square'] = {
        'lat': 40.7505085,
        'long': -73.9956274,
        'label': "Madison Square Garden Towers, New York, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['times-square'] = {
        'lat': 40.7589,
        'long': -73.9851,
        'label': "Times Square, New York, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['timessquare'] = {
        // 'lat': 40.7589,
        // 'long': -73.9851,
        // 'label': "Times Square, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'times-square'
      };

      this.landingLocations['midtown-west'] = {
        // 'lat': 40.7637581,
        // 'long': -73.99181809999999,
        // 'label': "Midtown West, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'midtown-west',
      };

      this.landingLocations['upper-west-side'] = {
        // 'lat': 40.7870106,
        // 'long': -73.97536760000003,
        // 'label': "Upper West Side, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'upper-west-side'
      };

      this.landingLocations['midtown-east'] = {
        // 'lat': 40.75403689999999,
        // 'long': -73.9668408,
        // 'label': "Midtown East, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'midtown-east'
      };
    }

  reset(formValues, formObj: NgForm) {
    formObj.resetForm();
  }


}
