import * as $ from 'jquery';

export class FilterHelper {

    public static TWO_HOUR_ERROR_MESSAGE = "In order to book a reservation, booking duration must be at least 2 hours in the future";
    public static PAST_TIME_ERROR_MESSAGE = "In order to book a reservation, the date/time selected must be at least 30 minutes in the future";
    public static MESSAGE_TITLE = "Error";

    public static defaultStartDuration() {
        let startDuration = this.changeNyTime()
        let minutes=Math.ceil(startDuration.getMinutes())
        if(minutes==0)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>19 && minutes<30)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>49 && minutes<=59)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        
        startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);

        startDuration.setMinutes(startDuration.getMinutes());
        startDuration.setSeconds(0);
        return startDuration;
    }
    public static defaultStartDurationForMin() {
        let startDuration = this.changeNyTime()
        let minutes=Math.ceil(startDuration.getMinutes())
        if(minutes==0)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>19 && minutes<30)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>49 && minutes<=59)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        
        startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);

        startDuration.setMinutes(startDuration.getMinutes()-15);
        startDuration.setSeconds(0);
        return startDuration;
    }
    public static defaultStartDurationUrlHomePageData(dat)
    {
        let startDuration = new Date(dat);
        let minutes=Math.ceil(startDuration.getMinutes())
        if(minutes==0)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>19 && minutes<30)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>49 && minutes<=59)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);
        startDuration.setMinutes(startDuration.getMinutes());
        startDuration.setSeconds(0);
        return startDuration;  
    }
    public static defaultStartDurationUrl(dat) {
        
        let startDuration = new Date(dat);
        let minutes=Math.ceil(startDuration.getMinutes())
        if(minutes==0)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>19 && minutes<30)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>49 && minutes<=59)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);
        startDuration.setMinutes(startDuration.getMinutes());
        startDuration.setSeconds(0);
        return startDuration;
    }
    public static defaultStartDurationUrlAfterNewHomePage(dat) {
        
        let startDuration = new Date(dat);
        let minutes=Math.ceil(startDuration.getMinutes())
       // startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);
       // startDuration.setMinutes(startDuration.getMinutes());
        startDuration.setSeconds(0);
        return startDuration;
    }

    public static defaultChangeDate(dat) {
        let endDuration = new Date(dat);
        endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        endDuration.setSeconds(0);
        return endDuration;
    }
    public static defaultStartDurationUrlForSafariHome(dat) { 
         // let startDuration = new Date(dat);
        // startDuration.setMinutes(startDuration.getMinutes()+0);
        // startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);
        // startDuration.setMinutes(startDuration.getMinutes());
        // startDuration.setSeconds(0);
        // return startDuration;
        let tzones:any = new Date()
        let startDurati=tzones.toString()
        let locs = startDurati.search("GMT");
        let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
        let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
        dat = dat+''+txt2
        let startDuration = new Date(dat);
        startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);
        startDuration.setMinutes(startDuration.getMinutes());
        startDuration.setSeconds(0);
        return startDuration;
    }
    public static defaultStartDurationUrlForSafari(dat) {
        // let startDuration = new Date(dat);
        // startDuration.setMinutes(startDuration.getMinutes()+0);
        // startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);
        // startDuration.setMinutes(startDuration.getMinutes());
        // startDuration.setSeconds(0);
        // return startDuration;
        let tzones:any = new Date()
        let startDurati=tzones.toString()
        let locs = startDurati.search("GMT");
        let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
        let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
        dat = dat+''+txt2
        let startDuration = new Date(dat);
        let minutes=Math.ceil(startDuration.getMinutes())
        if(minutes==0)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>19 && minutes<30)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>49 && minutes<=59)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);
        startDuration.setMinutes(startDuration.getMinutes());
        startDuration.setSeconds(0);
        return startDuration;
    }
    public static defaultStartDurationUrlForSafariEvents(dat)
    {
        let tzones:any = new Date()
        let startDurati=tzones.toString()
        let locs = startDurati.search("GMT");
        let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
        let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
        dat = dat+''+txt2
        let startDuration = new Date(dat);
        let minutes=Math.ceil(startDuration.getMinutes())
        if(minutes==0)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>19 && minutes<30)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        if(minutes>49 && minutes<=59)
        {
            startDuration.setMinutes(startDuration.getMinutes()+12);
        }
        startDuration.setMinutes(startDuration.getMinutes());
        startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);
        startDuration.setMinutes(startDuration.getMinutes());
        startDuration.setSeconds(0);
        return startDuration;
    }
    
    public static defaultEndDuration() {
        let endDuration = this.changeNyTime()
        let minutes=Math.ceil(endDuration.getMinutes())
        if(minutes==0)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        if(minutes>19 && minutes<30)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        if(minutes>49 && minutes<=59)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        endDuration.setMinutes(endDuration.getMinutes() + 120);
        endDuration.setSeconds(0);
        return endDuration;
    }
    public static defaultEndDurationUrlChnge(dat) {
        let endDuration = new Date(dat);
        let minutes=Math.ceil(endDuration.getMinutes())
        // if(minutes==0)
        // {
        //     endDuration.setMinutes(endDuration.getMinutes()+12);
        // }
        // if(minutes>19 && minutes<30)
        // {
        //     endDuration.setMinutes(endDuration.getMinutes()+12);
        // }
        // if(minutes>49 && minutes<=59)
        // {
        //     endDuration.setMinutes(endDuration.getMinutes()+12);
        // }
        // endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        endDuration.setMinutes(endDuration.getMinutes() + 120);
        endDuration.setSeconds(0);
        return endDuration;
    }
    
    public static defaultEndDurationUrl(dat) {
        let endDuration = new Date(dat);
        let minutes=Math.ceil(endDuration.getMinutes())
        if(minutes==0)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        if(minutes>19 && minutes<30)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        if(minutes>49 && minutes<=59)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        endDuration.setMinutes(endDuration.getMinutes() + 120);
        endDuration.setSeconds(0);
        return endDuration;
    }
    public static changeNyTime() {
        let offset:any ='-4'; 
        let d = new Date();
        let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        let nd = new Date(utc + (3600000*offset));
        return nd 
    }
    public static changeNyTimeFordayLength() {
        let offset:any ='-4'; 
        let d = new Date('2020-03-08 07:00:00');
        let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        let nd = new Date(utc + (3600000*offset));
        return nd 
    }
    public static defaultEndDurationUrlHomeSfari(dat) {
        // let endDuration = new Date(dat);
        // endDuration.setMinutes(endDuration.getMinutes()+0);
        // endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        // endDuration.setMinutes(endDuration.getMinutes() + 120);
        // endDuration.setSeconds(0);
        // return endDuration;
        let tzones:any = new Date(dat)
        let startDurati=tzones.toString()
        let locs = startDurati.search("GMT");
        let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
        let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
        dat = dat+''+txt2
        // alert(dat)
        let startDuration = new Date(dat);
        let endDuration = new Date(dat);
        // console.log(endDuration)
        // console.log("testing the code");
        
        //endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        //endDuration.setMinutes(endDuration.getMinutes() + 120);
        endDuration.setSeconds(0);
        return endDuration;
    }
    // public static defaultEndDurationUrlForSafarichange(dat) {
    //     // let endDuration = new Date(dat);
    //     // endDuration.setMinutes(endDuration.getMinutes()+0);
    //     // endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
    //     // endDuration.setMinutes(endDuration.getMinutes() + 120);
    //     // endDuration.setSeconds(0);
    //     // return endDuration;
    //     let tzones:any = new Date()
    //     let startDurati=tzones.toString()
    //     let locs = startDurati.search("GMT");
    //     let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
    //     let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
    //     dat = dat+''+txt2
    //     // alert(dat)
    //     let startDuration = new Date(dat);
    //     let endDuration = new Date(dat);
    //     let minutes=Math.ceil(endDuration.getMinutes());
    //     // if(minutes==0)
    //     // {
    //     //     endDuration.setMinutes(endDuration.getMinutes()+12);
    //     // }
    //     // if(minutes>19 && minutes<30)
    //     // {
    //     //     endDuration.setMinutes(endDuration.getMinutes()+12);
    //     // }
    //     // if(minutes>49 && minutes<=59)
    //     // {
    //     //     endDuration.setMinutes(endDuration.getMinutes()+12);
    //     // }
    //     // endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
    //     endDuration.setMinutes(endDuration.getMinutes() + 120);
    //     endDuration.setSeconds(0);
    //     return endDuration;
    // }
    public static defaultEndDurationUrlForSafari(dat) {
        // let endDuration = new Date(dat);
        // endDuration.setMinutes(endDuration.getMinutes()+0);
        // endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        // endDuration.setMinutes(endDuration.getMinutes() + 120);
        // endDuration.setSeconds(0);
        // return endDuration;
        let tzones:any = new Date()
        let startDurati=tzones.toString()
        let locs = startDurati.search("GMT");
        let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
        let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
        dat = dat+''+txt2
        // alert(dat)
        let startDuration = new Date(dat);
        let endDuration = new Date(dat);
        let minutes=Math.ceil(endDuration.getMinutes());
        if(minutes==0)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        if(minutes>19 && minutes<30)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        if(minutes>49 && minutes<=59)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        endDuration.setMinutes(endDuration.getMinutes() + 120);
        endDuration.setSeconds(0);
        return endDuration;
    }

    public static defaultEndDurationUrlForSafariForMoreTime(dat) {
        let DateArr = dat.split(" ");
        let fDate = this.formatDate(new Date(DateArr[0]))
        let Updated = DateArr[0]+"T"+ DateArr[1];
        return Updated+this.defaultEndDurationUrlForSafariOf();
    }

    public static defaultEndDurationUrlForSafariOf(){
        let tzones:any = new Date()
        let startDurati=tzones.toString()
        let locs = startDurati.search("GMT");
        let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
        let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
        return txt2;
    }
    public static defaultEndDurationUrlHome(dat)
     {
        let endDuration:any = new Date(dat);
        return endDuration;
     }
    public static defaultEndDurationUrlEvent(dat,strdat) {
        let startDate:any = new Date(strdat);
        let endDuration:any = new Date(dat);
        let hours = Math.abs(endDuration - startDate) / 36e5;
        if(endDuration<startDate)
        {
            startDate.setMinutes(Math.ceil(startDate.getMinutes() + 240));
            //startDate.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
            startDate.setSeconds(0);
            return startDate;
        }
        if(hours>24 || hours==0)
        {
            startDate.setMinutes(Math.ceil(startDate.getMinutes() + 240));
            //startDate.setMinutes(startDate.getMinutes() + 180);
            startDate.setSeconds(0);
            return startDate;
        }else{
            endDuration.setMinutes(Math.ceil(endDuration.getMinutes()+60));
            //endDuration.setMinutes(endDuration.getMinutes() + 180);
            endDuration.setSeconds(0);
           return endDuration;
        }
        //endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        //endDuration.setMinutes(endDuration.getMinutes() + 180);
        //endDuration.setSeconds(0);
       
    }
    public static defaultEndDurationUrlWidget(dat,strdat) {
        let startDate:any = new Date(strdat);
        let endDuration:any = new Date(dat);
        let hours = Math.abs(endDuration - startDate) / 36e5;
        if(endDuration<startDate)
        {
            startDate.setMinutes(Math.ceil(startDate.getMinutes() + 120));
            //startDate.setMinutes(startDate.getMinutes() + 180);
            startDate.setSeconds(0);
            return startDate;
        }
        if(hours==0)
        {
            startDate.setMinutes(Math.ceil(startDate.getMinutes() + 120));
            //startDate.setMinutes(startDate.getMinutes() + 180);
            startDate.setSeconds(0);
            return startDate;
        }else{
            endDuration.setMinutes(Math.ceil(endDuration.getMinutes()));
            //endDuration.setMinutes(endDuration.getMinutes() + 180);
            endDuration.setSeconds(0);
            return endDuration;
        }
        //endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        //endDuration.setMinutes(endDuration.getMinutes() + 180);
        //endDuration.setSeconds(0);
       
    }
    public static defaultEndDurationUrlEventSafari(dat,strdat) {
        let tzones:any = new Date()
        let startDurati=tzones.toString()
        let locs = startDurati.search("GMT");
        let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
        let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
        dat = dat+''+txt2
        let endDuration:any = new Date(dat);
        let minutes=Math.ceil(endDuration.getMinutes())
        if(minutes==0)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        if(minutes>19 && minutes<30)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        if(minutes>49 && minutes<=59)
        {
            endDuration.setMinutes(endDuration.getMinutes()+12);
        }
        ///strdat = strdat+''+txt2
        let startDate:any = new Date(strdat);
        let hours = Math.abs(endDuration - startDate) / 36e5;
        if(endDuration<startDate)
        {
            startDate.setMinutes(Math.ceil(startDate.getMinutes() + 240));
            //startDate.setMinutes(startDate.getMinutes() + 180);
            startDate.setSeconds(0);
            return startDate;
        }
        if(hours>24 || hours==0)
        {
            startDate.setMinutes(Math.ceil(startDate.getMinutes() + 240));
            //startDate.setMinutes(startDate.getMinutes() + 180);
            startDate.setSeconds(0);
            return startDate;
        }else{
            endDuration.setMinutes(Math.ceil(endDuration.getMinutes()+60));
            //endDuration.setMinutes(endDuration.getMinutes() + 180);
            endDuration.setSeconds(0);
            return endDuration;
        }
        //endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        //endDuration.setMinutes(endDuration.getMinutes() + 180);
        //endDuration.setSeconds(0);
       
    }
    public static defaultEndDurationUrlWidgetSafari(dat,strdat) {
        let startDate:any = new Date(strdat);
        let endDuration:any = new Date(dat);
        let hours = Math.abs(endDuration - startDate) / 36e5;
        if(endDuration<startDate)
        {
            startDate.setMinutes(Math.ceil(startDate.getMinutes() + 240));
            //startDate.setMinutes(startDate.getMinutes() + 180);
            startDate.setSeconds(0);
           return startDate;
        }
        if(hours>24 || hours==0)
        {
            startDate.setMinutes(Math.ceil(startDate.getMinutes() + 240));
            //startDate.setMinutes(startDate.getMinutes() + 180);
            startDate.setSeconds(0);
            return startDate;
        }else{
            endDuration.setMinutes(Math.ceil(endDuration.getMinutes()+60));
            //endDuration.setMinutes(endDuration.getMinutes() + 180);
            endDuration.setSeconds(0);
            return endDuration;
        }
        //endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        //endDuration.setMinutes(endDuration.getMinutes() + 180);
        //endDuration.setSeconds(0);
       
    }
    public static formatPaymentInitDate(dateString) {
        let newDate = dateString.split("-");
        return new Date(newDate[0] + '/' + newDate[1] + '/' + newDate[2]);
    }

    public static formatPaymentMonthlyDate(dateString) {
        let newDate = dateString.split("-");
        return new Date(newDate[1] + '/' + newDate[2] + '/' + newDate[0]);
    }
    public static formatPaymentMonthlyDateCompare(dateString) {
        let newDate = dateString.split("-");
        let newDates= new Date(newDate[1] + '/' + newDate[2] + '/' + newDate[0]);
        newDates.setHours(23,59,59,999);
        return newDates
    }
    public static formatPaymentMonthlyDateNew(d) {
        let date = d.getDate();
        let month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
        let year = d.getFullYear();
        return new Date(month + '/' + date + '/' + year);
        // let newDate = dateString.split("-");
        // let newDates= new Date(newDate[1] + '/' + newDate[2] + '/' + newDate[0]);
        // newDates.setHours(23,59,59,999);
        //return newDates
    }
    public static formatPaymentMonthlyDateNewFOrFormat(d) {
        let date = d.getDate();
        let month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
        let year = d.getFullYear();
        return year + '-' + month + '-' + date;
        // let newDate = dateString.split("-");
        // let newDates= new Date(newDate[1] + '/' + newDate[2] + '/' + newDate[0]);
        // newDates.setHours(23,59,59,999);
        //return newDates
    }
    
    public static formatMonthlyDate(dateString) {
        let newDate = dateString.split("/");
        return new Date(newDate[0] + '-' + newDate[1]+1 + '-' + newDate[2]);
    }

    public static getFormattedTime(date) { // return 02:00 PM ~ String Format
        let hour = (date.getHours()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        let minutes = (date.getMinutes()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        let seconds = '00';
        
        minutes =("0" + (Math.floor(minutes/10))*10).slice(-2) ;
        let formattedTime = hour + ':' + minutes + ':' + seconds;
        
        return formattedTime;
    }
    public static getFormattedTimeWidget(date) { // return 02:00 PM ~ String Format
        date.setMinutes(date.getMinutes()+30)
        let hour = (date.getHours()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        let minutes = (date.getMinutes()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        let seconds = '00';
        let formattedTime = hour + ':' + minutes + ':' + seconds;
        return formattedTime;
    }

    public static removeSpecialCharacters(variable) {
        return variable.toLocaleString().replace(/[^ -~]/g, '');
    }
    public static displayableTimeformatforfaciliy(times)
    {
        if (times != null) {
            let timeData=times.split(" ");
            let time=timeData[1];
            let hour:any = parseInt(time.split(":")[0]);
            let minutes:any = parseInt(time.split(":")[1]);
            let phase = 'AM';
            if (hour > 12) { hour = hour - 12; phase = 'PM'; }
            if (hour == 12) { phase = 'PM'; }
            hour = (hour).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
            minutes = (minutes).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
            let displayableTimeFormat = hour + ':' + minutes + ' ' + phase;
            return displayableTimeFormat;
        }
    }
    public static displayableTimeformat(time) {
        if (time != null) {
            let hour:any = parseInt(time.split(":")[0]);
            let minutes:any = parseInt(time.split(":")[1]);
            let phase = 'AM';
            if (hour > 12) { hour = hour - 12; phase = 'PM'; }
            if (hour == 12) { phase = 'PM'; }
            hour = (hour).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
            minutes = (minutes).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
            let displayableTimeFormat = hour + ':' + minutes + ' ' + phase;
            return displayableTimeFormat;
        }
    }

    public static makeDurationDateObject(date, time) { // Date Object, Time String Like : 02:00 PM
        if (time != null && date != null) {
            let hour, minute, phase;
            if (time.toString().indexOf('PM') > -1 || time.toString().indexOf('AM') > -1) {
                let temporaryTime = time.split(" ");
                hour = temporaryTime[0].split(":")[0];
                minute = temporaryTime[0].split(":")[1];
                phase = temporaryTime[1];

                if (phase == 'PM' && hour != 12) {
                    hour = parseInt(hour) + 12;
                }
            } else {
                let tempTime = time.split(":");
                hour = tempTime[0];
                minute = tempTime[1];
            }
            

            let durationDate = new Date(date);
            durationDate.setHours(hour);
            durationDate.setMinutes(minute);
            return durationDate;
        }
    }
    public static makeDurationDateObjectSort(date, time) { // Date Object, Time String Like : 02:00 PM
        if (time != null && date != null) {
            let hour, minute, phase;
            if (time.toString().indexOf('PM') > -1 || time.toString().indexOf('AM') > -1) {
                let temporaryTime = time.split(" ");
                hour = temporaryTime[0].split(":")[0];
                minute = temporaryTime[0].split(":")[1];
                phase = temporaryTime[1];

                if (phase == 'PM' && hour != 12) {
                    hour = parseInt(hour) + 12;
                }
            } else {
                let tempTime = time.split(":");
                hour = tempTime[0];
                minute = tempTime[1];
            }
            

            let durationDate = new Date(date);
            durationDate.setHours(durationDate.getHours()+2);
            durationDate.setMinutes(minute);
            return durationDate;
        }
    }
    public static getBookingHours_yy(startDate, startTime, endDate, endTime) {
        let startDuration = this.makeDurationDateObject(startDate, startTime);
        let endDuration = this.makeDurationDateObject(endDate, endTime);
        return this.calculateHours(startDuration, endDuration);
    }
    public static getBookingHours_new(startDate, startTime, endDate, endTime) {
        let startDuration = this.makeDurationDateObject(startDate, startTime);
        let endDuration = this.makeDurationDateObject(endDate, endTime);

        let d = new Date(startDuration).getDate();
        let m = (new Date(startDuration).getMonth())+1;
        let zone  = new Date(startDuration).getTimezoneOffset();
      
        let starttest=0
        if(d<8 && m<=3){ starttest=1 }

        let de = new Date(endDuration).getDate();
        let me = (new Date(endDuration).getMonth())+1; 
        let he = new Date(endDuration).getHours(); 
        
        let endtest=0
        if((de>14 && me>=3 && he>=2) || de==14 && me>=3 && he>2){ endtest=1 }

        let calHours = this.calculateHours(startDuration, endDuration);
        if(starttest && endtest && zone == 300){
            return (calHours + 1);
        }
        return calHours;
    }
    public static getBookingHours_old(startDate, startTime, endDate, endTime) {
        let startDuration = this.makeDurationDateObject(startDate, startTime);
        let endDuration = this.makeDurationDateObject(endDate, endTime);

        let d = new Date(startDuration).getDate();
        let m = (new Date(startDuration).getMonth())+1;
        let zone  = new Date(startDuration).getTimezoneOffset();
      
        let starttest=0
        if(d<8 && m<=3){ starttest=1 }

        let de = new Date(endDuration).getDate();
        let me = (new Date(endDuration).getMonth())+1; 
        let he = new Date(endDuration).getHours(); 
        
        let endtest=0
        if(de>=8 && me>=3 && he>=2){ endtest=1 }

        let calHours = this.calculateHours(startDuration, endDuration);
        if(starttest && endtest && zone == 300){
            return (calHours + 1);
        }
        return calHours;
    }

    public static getBookingHours(startDate, startTime, endDate, endTime) {
        let startDuration = this.makeDurationDateObject(startDate, startTime);
        let endDuration = this.makeDurationDateObject(endDate, endTime);

        let d = new Date(startDuration).getDate();
        let m = (new Date(startDuration).getMonth())+1;
        let h = new Date(startDuration).getHours(); 
        let zone  = new Date(startDuration).getTimezoneOffset();
      
        let starttest=0
        if((d<14 && m<=3) || (d==14 && m>=3 && h<2)){ starttest=1 }

        let de = new Date(endDuration).getDate();
        let me = (new Date(endDuration).getMonth())+1; 
        let he = new Date(endDuration).getHours(); 
        
        let endtest=0
        if((de>14 && me>=3 && he>=2) || de==14 && me>=3 && he>2){ endtest=1 }

        let calHours = this.calculateHours(startDuration, endDuration);
        //alert(zone);\
        sessionStorage.setItem('is_extra','normal')
        if(starttest && endtest && zone == 300){
            sessionStorage.setItem('is_extra','extra')
            return (calHours + 1);
        }
        return calHours;
    }

    public static calculateHours(start, end) {
        let bookingHours:any = (end - start) / 60000;
        bookingHours = bookingHours / 60;
        return bookingHours;
    }

    public static getCurrentActiveSearchType() {
        return $('label[name="monthlytype"].active').text().trim().toLowerCase();
    }

    public static activateDailySearchFilter() {
        setTimeout(() => {
            $('#monthlyFilter').addClass('hidden');
            $('#dailyFilter').removeClass('hidden');
            $('label#daily').addClass('active');
            $('label#monthly').removeClass('active');
        }, 100);
    }

    public static activateMonthlySearchFilter() {
        setTimeout(() => {
            $('#monthlyFilter').removeClass('hidden');
            $('#dailyFilter').addClass('hidden');
            $('label#monthly').addClass('active');
            $('label#daily').removeClass('active');
        }, 100);
    }

    public static formatDate(date) {
        let day = date.getDate();
        let month = (date.getMonth() + 1);
        let year = date.getFullYear();
        day = (day).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        month = (month).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        const formatedDate = month + '/' + day + '/' + year;
        return formatedDate.toString();
    }

    public static formatArrivalDate(date) {
        let day = date.getDate();
        let month = (date.getMonth() + 1);
        let year = date.getFullYear();
        const formatedDate = year + '-' + month + '-' + day;
        return formatedDate.toString();
    }

    public static formatBuyMonthlyDate(date) {
        let day = date.getDate();
        let month = (date.getMonth() + 1);
        let year = date.getFullYear();
        day = (day).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        month = (month).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        const formatedDate = year + '-' + month + '-' + day;
        return formatedDate.toString();
    }

    public static getArrivalTime(startDate, startTime) {
        let currDate = this.formatArrivalDate(startDate) + ' ' + startTime;
        return currDate.toLocaleString().replace(/[^ -~]/g,'');
    }

    public static generateArrivalTime(duration) {
        let day = duration.getDate();
        let month = (duration.getMonth() + 1);
       let year = duration.getFullYear();
        let hour = duration.getHours();
        let minutes = duration.getMinutes();
        hour = (hour).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        minutes = (minutes).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

        let arrivalTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':00';
        return arrivalTime;
    }

    public static getObjectBasedTimeSlots() {
        return [
            { value: "00:00:00", time : "12:00 AM" }, { value: "00:30:00", time : "12:30 AM" },
            { value: "01:00:00", time : "01:00 AM" }, { value: "01:30:00", time : "01:30 AM" },
            { value: "02:00:00", time : "02:00 AM" }, { value: "02:30:00", time : "02:30 AM" },
            { value: "03:00:00", time : "03:00 AM" }, { value: "03:30:00", time : "03:30 AM" },
            { value: "04:00:00", time : "04:00 AM" }, { value: "04:30:00", time : "04:30 AM" },
            { value: "05:00:00", time : "05:00 AM" }, { value: "05:30:00", time : "05:30 AM" },
            { value: "06:00:00", time : "06:00 AM" }, { value: "06:30:00", time : "06:30 AM" },
            { value: "07:00:00", time : "07:00 AM" }, { value: "07:30:00", time : "07:30 AM" },         
            { value: "08:00:00", time : "08:00 AM" }, { value: "08:30:00", time : "08:30 AM" },
            { value: "09:00:00", time : "09:00 AM" }, { value: "09:30:00", time : "09:30 AM" },
            { value: "10:00:00", time : "10:00 AM" }, { value: "10:30:00", time : "10:30 AM" },
            { value: "11:00:00", time : "11:00 AM" }, { value: "11:30:00", time : "11:30 AM" },
            { value: "12:00:00", time : "12:00 PM" }, { value: "12:30:00", time : "12:30 PM" },         
            { value: "13:00:00", time : "01:00 PM" }, { value: "13:30:00", time : "01:30 PM" },
            { value: "14:00:00", time : "02:00 PM" }, { value: "14:30:00", time : "02:30 PM" },
            { value: "15:00:00", time : "03:00 PM" }, { value: "15:30:00", time : "03:30 PM" },
            { value: "16:00:00", time : "04:00 PM" }, { value: "16:30:00", time : "04:30 PM" },
            { value: "17:00:00", time : "05:00 PM" }, { value: "17:30:00", time : "05:30 PM" },
            { value: "18:00:00", time : "06:00 PM" }, { value: "18:30:00", time : "06:30 PM" },
            { value: "19:00:00", time : "07:00 PM" }, { value: "19:30:00", time : "07:30 PM" },         
            { value: "20:00:00", time : "08:00 PM" }, { value: "20:30:00", time : "08:30 PM" },
            { value: "21:00:00", time : "09:00 PM" }, { value: "21:30:00", time : "09:30 PM" },
            { value: "22:00:00", time : "10:00 PM" }, { value: "22:30:00", time : "10:30 PM" },
            { value: "23:00:00", time : "11:00 PM" }, { value: "23:30:00", time : "11:30 PM" }
        ];
    }

    public static getStraightForwardTimeSlots() {
        return [
            "12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM", "03:00 AM", "03:30 AM",
            "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", 
            "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
            "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM", "03:00 PM", "03:30 PM", 
            "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM", "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", 
            "08:00 PM", "08:30 PM", "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"
        ];
    }
    public static getDateInFormat(dat) {
        const dt = new Date(dat);
        let month = ("0" + (dt.getMonth() + 1)).slice(-2)//months from 1-12
        let day = ("0" + (dt.getDate())).slice(-2) ;
        let year = dt.getFullYear();
        const h = ("0" + (dt.getHours())).slice(-2), m = ("0" + (dt.getMinutes())).slice(-2), s =("0" + (dt.getSeconds())).slice(-2) ;
        const _time = year+'-'+month+'-'+day+'T'+h + ':' + m + ':' + s;
        //const _time = month+'-'+day+'-'+year+' '+h + ':' + m + ':' + s;
        return _time;
        //return new Date().toISOString().slice(0, 10) + '' + _time;
    }
    public static defaultEndstartDate() {
        let endDuration = new Date();
        endDuration.setDate(endDuration.getDate()+89);
        //endDuration.setDate(endDuration.getDate() - 1);
        return endDuration;
    }
    public static defaultEndtoDate() {
        let endDuration = new Date();
        endDuration.setMonth(endDuration.getMonth()+1);
        endDuration.setDate(endDuration.getDate() - 1);
        return endDuration;
    }
    public static rangeMonthDate(date) {
        let endDuration = new Date(date);
        endDuration.setMonth(endDuration.getMonth()+1);
        endDuration.setDate(endDuration.getDate() - 1);
        return endDuration;
    }
    public static addDays(startDate,numberOfDays)
    {
        var returnDate = new Date(
                                startDate.getFullYear(),
                                startDate.getMonth(),
                                startDate.getDate()+numberOfDays,
                                startDate.getHours(),
                                startDate.getMinutes(),
                                startDate.getSeconds());
        return returnDate;
    }
    public static cDate(startDate)
    {
        var returnDate = new Date(
                                startDate.getFullYear(),
                                startDate.getMonth(),
                                startDate.getDate(),
                                startDate.getHours(),
                                startDate.getMinutes(),
                                startDate.getSeconds());
        
        return returnDate;
    }


    public static defaultEndDurationUrlForSafarichange(dat) {
        // let endDuration = new Date(dat);
        // endDuration.setMinutes(endDuration.getMinutes()+0);
        // endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        // endDuration.setMinutes(endDuration.getMinutes() + 120);
        // endDuration.setSeconds(0);
        // return endDuration;
        let tzones:any = new Date()
        let startDurati=tzones.toString()
        let locs = startDurati.search("GMT");
        let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
        let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
        dat = dat+''+txt2
        // alert(dat)
        let startDuration = new Date(dat);
        let endDuration = new Date(dat);
        let minutes=Math.ceil(endDuration.getMinutes());
        // if(minutes==0)
        // {
        //     endDuration.setMinutes(endDuration.getMinutes()+12);
        // }
        // if(minutes>19 && minutes<30)
        // {
        //     endDuration.setMinutes(endDuration.getMinutes()+12);
        // }
        // if(minutes>49 && minutes<=59)
        // {
        //     endDuration.setMinutes(endDuration.getMinutes()+12);
        // }
        // endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
        endDuration.setMinutes(endDuration.getMinutes() + 120);
        endDuration.setSeconds(0);
        return endDuration;
    }
  
//     public static defaultEndDurationUrlChnge(dat) {
//       let endDuration = new Date(dat);
//       let minutes=Math.ceil(endDuration.getMinutes())
//       // if(minutes==0)
//       // {
//       //     endDuration.setMinutes(endDuration.getMinutes()+12);
//       // }
//       // if(minutes>19 && minutes<30)
//       // {
//       //     endDuration.setMinutes(endDuration.getMinutes()+12);
//       // }
//       // if(minutes>49 && minutes<=59)
//       // {
//       //     endDuration.setMinutes(endDuration.getMinutes()+12);
//       // }
//       // endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
//       endDuration.setMinutes(endDuration.getMinutes() + 120);
//       endDuration.setSeconds(0);
//       return endDuration;
//   }


//   public static makeDurationDateObjectSort(date, time) { // Date Object, Time String Like : 02:00 PM
//     if (time != null && date != null) {
//         let hour, minute, phase;
//         if (time.toString().indexOf('PM') > -1 || time.toString().indexOf('AM') > -1) {
//             let temporaryTime = time.split(" ");
//             hour = temporaryTime[0].split(":")[0];
//             minute = temporaryTime[0].split(":")[1];
//             phase = temporaryTime[1];

//             if (phase == 'PM' && hour != 12) {
//                 hour = parseInt(hour) + 12;
//             }
//         } else {
//             let tempTime = time.split(":");
//             hour = tempTime[0];
//             minute = tempTime[1];
//         }
        

//         let durationDate = new Date(date);
//         durationDate.setHours(durationDate.getHours()+2);
//         durationDate.setMinutes(minute);
//         return durationDate;
//     }
// }

}
// export class FilterHelper {

//  public static TWO_HOUR_ERROR_MESSAGE = "In order to book a reservation, booking duration must be at least 2 hours in the future";
//  public static PAST_TIME_ERROR_MESSAGE = "In order to book a reservation, the date/time selected must be at least 30 minutes in the future";
//  public static MESSAGE_TITLE = "Error";

//  public static defaultStartDuration() {
//      let startDuration = new Date();
//         startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);

//         startDuration.setMinutes(startDuration.getMinutes());
//         startDuration.setSeconds(0);
//         return startDuration;
//  }
//  public static defaultStartDurationUrl(dat) {
//      let startDuration = new Date(dat);
//         startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);
//         startDuration.setMinutes(startDuration.getMinutes());
//         startDuration.setSeconds(0);
//         return startDuration;
//  }
//  public static defaultStartDurationUrlForSafari(dat,minusHour) {
//      let startDuration = new Date(dat);
//      if(minusHour=='Asia/Calcutta')
//      {
//          startDuration.setMinutes(startDuration.getMinutes() - 330);
//      }
//      else{
//          startDuration.setMinutes(startDuration.getMinutes()+0);
//      }
//         startDuration.setMinutes(Math.ceil(startDuration.getMinutes() / 30) * 30);
//         startDuration.setMinutes(startDuration.getMinutes());
//         startDuration.setSeconds(0);
//         return startDuration;
//  }
//  public static defaultEndDuration() {
//      let endDuration = new Date();
//         endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
//         endDuration.setMinutes(endDuration.getMinutes() + 120);
//         endDuration.setSeconds(0);
//         return endDuration;
//  }
//  public static defaultEndDurationUrl(dat) {
//      let endDuration = new Date(dat);
//         endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
//         endDuration.setMinutes(endDuration.getMinutes() + 120);
//         endDuration.setSeconds(0);
//         return endDuration;
//  }
//  public static defaultEndDurationUrlForSafari(dat,minusHour) {
//      let endDuration = new Date(dat);
//      if(minusHour=='Asia/Calcutta')
//      {
//          endDuration.setMinutes(endDuration.getMinutes() - 330);
//      }
//      else{
//          endDuration.setMinutes(endDuration.getMinutes()+0);
//      }
//         endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
//         endDuration.setMinutes(endDuration.getMinutes() + 120);
//         endDuration.setSeconds(0);
//         return endDuration;
//  }

//  public static defaultEndDurationUrlEventTest(dat,strdat) {
//      let startDate:any = new Date(strdat);
//      let endDuration:any = new Date(dat);
//      var hours = Math.abs(endDuration - startDate) / 36e5;
//      let returnData='';
//      if(hours>24)
//      {
//          startDate.setMinutes(240);
//          return startDate;
//      }else{
//          endDuration.setMinutes(60);
//          return endDuration;
//      }
        
//         //endDuration.setMinutes(endDuration.getMinutes() + 180);
//         //endDuration.setSeconds(0);
        
//  }
//  public static defaultEndDurationUrlEventTestSafari(dat,strdat) {
//      let startDate = new Date(strdat);
//      let endDuration = new Date(dat);
//         endDuration.setMinutes(60);
//         //endDuration.setMinutes(endDuration.getMinutes() + 180);
//         //endDuration.setSeconds(0);
//         return endDuration;
//  }
//  public static defaultEndDurationUrlEvent(dat) {
//      let endDuration = new Date(dat);
//         endDuration.setMinutes(Math.ceil(endDuration.getMinutes() / 30) * 30);
//         endDuration.setMinutes(endDuration.getMinutes() + 180);
//         endDuration.setSeconds(0);
//         return endDuration;
//  }
    
//  public static formatPaymentInitDate(dateString) {
//      let newDate = dateString.split("-");
//      return new Date(newDate[0] + '/' + newDate[1] + '/' + newDate[2]);
//  }

//  public static formatPaymentMonthlyDate(dateString) {
//      let newDate = dateString.split("-");
//      return new Date(newDate[1] + '/' + newDate[2] + '/' + newDate[0]);
//  }
    
//            public static formatPaymentInitDate(dateString) {
//                            let newDate = dateString.split("-");
//                            return new Date(newDate[0] + '/' + newDate[1] + '/' + newDate[2]);
//            }

//            public static formatPaymentMonthlyDate(dateString) {
//                            let newDate = dateString.split("-");
//                            return new Date(newDate[1] + '/' + newDate[2] + '/' + newDate[0]);
//            }

//     public static formatMonthlyDate(dateString) {
//      let newDate = dateString.split("/");
//      return new Date(newDate[0] + '-' + newDate[1]+1 + '-' + newDate[2]);
//  }

//  public static getFormattedTime(date) { // return 02:00 PM ~ String Format
//      let hour = (date.getHours()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
//          let minutes = (date.getMinutes()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
//          let seconds = '00';
//      let formattedTime = hour + ':' + minutes + ':' + seconds;
//      return formattedTime;
//  }

//  public static removeSpecialCharacters(variable) {
//      return variable.toLocaleString().replace(/[^ -~]/g, '');
//  }

//  public static displayableTimeformat(time) {
//      if (time != null) {
//          let hour:any = parseInt(time.split(":")[0]);
//          let minutes:any = parseInt(time.split(":")[1]);
//          let phase = 'AM';
//          if (hour > 12) { hour = hour - 12; phase = 'PM'; }
//          if (hour == 12) { phase = 'PM'; }
//          hour = (hour).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
//          minutes = (minutes).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
//          let displayableTimeFormat = hour + ':' + minutes + ' ' + phase;
//          return displayableTimeFormat;
//      }
//  }

//  public static makeDurationDateObject(date, time) { // Date Object, Time String Like : 02:00 PM
//      if (time != null && date != null) {
//          let hour, minute, phase;
//          if (time.toString().indexOf('PM') > -1 || time.toString().indexOf('AM') > -1) {
//              let temporaryTime = time.split(" ");
//              hour = temporaryTime[0].split(":")[0];
//              minute = temporaryTime[0].split(":")[1];
//              phase = temporaryTime[1];

//              if (phase == 'PM' && hour != 12) {
//                  hour = parseInt(hour) + 12;
//              }
//          } else {
//              let tempTime = time.split(":");
//              hour = tempTime[0];
//              minute = tempTime[1];
//          }
            

//          let durationDate = new Date(date);
//          durationDate.setHours(hour);
//          durationDate.setMinutes(minute);
//          return durationDate;
//      }
//  }

//  public static getBookingHours(startDate, startTime, endDate, endTime) {
//      let startDuration = this.makeDurationDateObject(startDate, startTime);
//      let endDuration = this.makeDurationDateObject(endDate, endTime);
//      return this.calculateHours(startDuration, endDuration);
//  }

//  public static calculateHours(start, end) {
//      let bookingHours:any = (end - start) / 60000;
//      bookingHours = bookingHours / 60;
//      return bookingHours;
//  }

//  public static getCurrentActiveSearchType() {
//      return $('label[name="monthlytype"].active').text().trim().toLowerCase();
//  }

//  public static activateDailySearchFilter() {
//      setTimeout(() => {
//          $('#monthlyFilter').addClass('hidden');
//          $('#dailyFilter').removeClass('hidden');
//          $('label#daily').addClass('active');
//          $('label#monthly').removeClass('active');
//      }, 100);
//  }

//  public static activateMonthlySearchFilter() {
//      setTimeout(() => {
//          $('#monthlyFilter').removeClass('hidden');
//          $('#dailyFilter').addClass('hidden');
//          $('label#monthly').addClass('active');
//          $('label#daily').removeClass('active');
//      }, 100);
//  }

//  public static formatDate(date) {
//      let day = date.getDate();
//      let month = (date.getMonth() + 1);
//      let year = date.getFullYear();
//      day = (day).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
//      month = (month).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
//      const formatedDate = month + '/' + day + '/' + year;
//      return formatedDate.toString();
//  }

//  public static formatArrivalDate(date) {
//      let day = date.getDate();
//      let month = (date.getMonth() + 1);
//      let year = date.getFullYear();
//      const formatedDate = year + '-' + month + '-' + day;
//      return formatedDate.toString();
//  }

//  public static formatBuyMonthlyDate(date) {
//      let day = date.getDate();
//      let month = (date.getMonth() + 1);
//      let year = date.getFullYear();
//      day = (day).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
//      month = (month).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
//      const formatedDate = year + '-' + month + '-' + day;
//      return formatedDate.toString();
//  }

//  public static getArrivalTime(startDate, startTime) {
//      let currDate = this.formatArrivalDate(startDate) + ' ' + startTime;
//          return currDate.toLocaleString().replace(/[^ -~]/g,'');
//  }

//  public static generateArrivalTime(duration) {
//      let day = duration.getDate();
//      let month = (duration.getMonth() + 1);
//      let year = duration.getFullYear();
//      let hour = duration.getHours();
//      let minutes = duration.getMinutes();
//      hour = (hour).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
//      minutes = (minutes).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

//      let arrivalTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':00';
//      return arrivalTime;
//  }

//  public static getObjectBasedTimeSlots() {
//      return [
//          { value: "00:00:00", time : "12:00 AM" }, { value: "00:30:00", time : "12:30 AM" },
//          { value: "01:00:00", time : "01:00 AM" }, { value: "01:30:00", time : "01:30 AM" },
//          { value: "02:00:00", time : "02:00 AM" }, { value: "02:30:00", time : "02:30 AM" },
//          { value: "03:00:00", time : "03:00 AM" }, { value: "03:30:00", time : "03:30 AM" },
//          { value: "04:00:00", time : "04:00 AM" }, { value: "04:30:00", time : "04:30 AM" },
//          { value: "05:00:00", time : "05:00 AM" }, { value: "05:30:00", time : "05:30 AM" },
//          { value: "06:00:00", time : "06:00 AM" }, { value: "06:30:00", time : "06:30 AM" },
//          { value: "07:00:00", time : "07:00 AM" }, { value: "07:30:00", time : "07:30 AM" },         
//          { value: "08:00:00", time : "08:00 AM" }, { value: "08:30:00", time : "08:30 AM" },
//          { value: "09:00:00", time : "09:00 AM" }, { value: "09:30:00", time : "09:30 AM" },
//          { value: "10:00:00", time : "10:00 AM" }, { value: "10:30:00", time : "10:30 AM" },
//          { value: "11:00:00", time : "11:00 AM" }, { value: "11:30:00", time : "11:30 AM" },
//          { value: "12:00:00", time : "12:00 PM" }, { value: "12:30:00", time : "12:30 PM" },         
//          { value: "13:00:00", time : "01:00 PM" }, { value: "13:30:00", time : "01:30 PM" },
//          { value: "14:00:00", time : "02:00 PM" }, { value: "14:30:00", time : "02:30 PM" },
//          { value: "15:00:00", time : "03:00 PM" }, { value: "15:30:00", time : "03:30 PM" },
//          { value: "16:00:00", time : "04:00 PM" }, { value: "16:30:00", time : "04:30 PM" },
//          { value: "17:00:00", time : "05:00 PM" }, { value: "17:30:00", time : "05:30 PM" },
//          { value: "18:00:00", time : "06:00 PM" }, { value: "18:30:00", time : "06:30 PM" },
//          { value: "19:00:00", time : "07:00 PM" }, { value: "19:30:00", time : "07:30 PM" },         
//          { value: "20:00:00", time : "08:00 PM" }, { value: "20:30:00", time : "08:30 PM" },
//          { value: "21:00:00", time : "09:00 PM" }, { value: "21:30:00", time : "09:30 PM" },
//          { value: "22:00:00", time : "10:00 PM" }, { value: "22:30:00", time : "10:30 PM" },
//          { value: "23:00:00", time : "11:00 PM" }, { value: "23:30:00", time : "11:30 PM" }
//      ];
//  }

//  public static getStraightForwardTimeSlots() {
//      return [
//          "12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM", "03:00 AM", "03:30 AM",
//          "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", 
//          "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
//          "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM", "03:00 PM", "03:30 PM", 
//          "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM", "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", 
//          "08:00 PM", "08:30 PM", "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"
//      ];
//  }

//  public static defaultEndstartDate() {
//      let endDuration = new Date();
//      endDuration.setDate(endDuration.getDate()+89);
//      //endDuration.setDate(endDuration.getDate() - 1);
//         return endDuration;
//  }
//  public static defaultEndtoDate() {
//      let endDuration = new Date();
//      endDuration.setMonth(endDuration.getMonth()+2);
//         endDuration.setDate(endDuration.getDate() - 1);
//         return endDuration;
//  }
//  public static rangeMonthDate(date) {
//      let endDuration = new Date(date);
//      endDuration.setMonth(endDuration.getMonth()+2);
//      endDuration.setDate(endDuration.getDate() - 1);
//         return endDuration;
//  }
//  public static addDays(startDate,numberOfDays)
//  {
//      var returnDate = new Date(
//                              startDate.getFullYear(),
//                              startDate.getMonth(),
//                              startDate.getDate()+numberOfDays,
//                              startDate.getHours(),
//                              startDate.getMinutes(),
//                              startDate.getSeconds());
//      return returnDate;
//  }
//  public static cDate(startDate)
//  {
//      var returnDate = new Date(
//                              startDate.getFullYear(),
//                              startDate.getMonth(),
//                              startDate.getDate(),
//                              startDate.getHours(),
//                              startDate.getMinutes(),
//                              startDate.getSeconds());
        
//      return returnDate;
//  }

// }


