import { Injectable } from '@angular/core';

//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import { DomSanitizer } from '@angular/platform-browser';

import * as FileSaver from 'file-saver';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class ResetPasswordService {

constructor(private http: HttpClient) { }


private getChangeUserPassword = CustomCode.APIURL + 'user/reset-password/email';

private actChangeUserPassword = CustomCode.APIURL + 'user/reset-password';

//https://api.iconparkingsystems.com/user/reset-password

 //{old_password: "adadasdsadasd", password: "aaaaaaaa", password_confirmation: "aaaaaaaa"}
  changeuserpassword(chpadata){
	  
	  const headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
	headers.append('Authorization', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers });
	const formDetails = {'email': chpadata.email};
	
    return this.http.post(this.getChangeUserPassword, formDetails, { headers: headers })
    .map((res: Response) => res)
    .catch((error: any) => Observable.throw(error));  
	  
  }
  
  changeactuserpassword(chpadata){
	  
	  const headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
	headers.append('Authorization', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers });
	const formDetails = {'password': chpadata.password, 'password_confirmation': chpadata.password_confirmation, 'token': chpadata.token};
	
    return this.http.post(this.actChangeUserPassword, formDetails, { headers: headers })
    .map((res: Response) => res)
    .catch((error: any) => Observable.throw(error));  
	  
  }
}
