
import { Injectable } from '@angular/core';
import { WindowRefService } from '../services/window-ref.service';

@Injectable()
export class Crypto {

	public encryptKey = 'Tw5qNOJLe5GFe7rjFsentNNQBsRHstbNzTP/18lkjnY=';

	constructor(public winRef: WindowRefService) {}

	encrypt(d) {
		const nonceString = d.name_on_card + ':' + d.card_number + ':' + d.expiration_date + ':' + d.security_code + ':' + d.zip_code;
		const CryptoJS = this.winRef.window.CryptoJS;
        const CryptoJSAesJson = {
            stringify: function (cipherParams) {
                const j = {
                	ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
                	iv: '',
                	s: ''
                };
                if (cipherParams.iv) j.iv = cipherParams.iv.toString();
                if (cipherParams.salt) j.s = cipherParams.salt.toString();
                return JSON.stringify(j);
            },
            parse: function (jsonStr) {
                const j = JSON.parse(jsonStr);
                const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
                if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
                if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
                return cipherParams;
            }
        };
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(nonceString), this.encryptKey, { format: CryptoJSAesJson }).toString();
        return encrypted;
    }
    
    encryptCard(d) {
		const nonceString = d.token + ':' + d.type + ':' + d.length + ':' + d.bin+ ':' + d.last_four+ ':' + d.expiration+ ':' + d.zip+ ':' + d.name_on_card+ ':' + d.card_number+ ':' + d.security_code;
		const CryptoJS = this.winRef.window.CryptoJS;
        const CryptoJSAesJson = {
            stringify: function (cipherParams) {
                const j = {
                	ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
                	iv: '',
                	s: ''
                };
                if (cipherParams.iv) j.iv = cipherParams.iv.toString();
                if (cipherParams.salt) j.s = cipherParams.salt.toString();
                return JSON.stringify(j);
            },
            parse: function (jsonStr) {
                const j = JSON.parse(jsonStr);
                const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
                if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
                if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
                return cipherParams;
            }
        };
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(nonceString), this.encryptKey, { format: CryptoJSAesJson }).toString();
        return encrypted;
    }

    encryptAuth(d) {
		const nonceString = d.name_on_card + ':' + d.card_number + ':' + d.expiration_date + ':' + d.security_code + ':' +d.zip_code;
		const CryptoJS = this.winRef.window.CryptoJS;
        const CryptoJSAesJson = {
            stringify: function (cipherParams) {
                const j = {
                	ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
                	iv: '',
                	s: ''
                };
                if (cipherParams.iv) j.iv = cipherParams.iv.toString();
                if (cipherParams.salt) j.s = cipherParams.salt.toString();
                return JSON.stringify(j);
            },
            parse: function (jsonStr) {
                const j = JSON.parse(jsonStr);
                const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
                if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
                if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
                return cipherParams;
            }
        };
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(nonceString), this.encryptKey, { format: CryptoJSAesJson }).toString();
        return encrypted;
    }

    decrypt(str) {
        const CryptoJS = this.winRef.window.CryptoJS;
        const CryptoJSAesJson = {
            stringify: function (cipherParams) {
                const j = {
                	ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
                	iv: '',
                	s: ''
                };
                if (cipherParams.iv) j.iv = cipherParams.iv.toString();
                if (cipherParams.salt) j.s = cipherParams.salt.toString();
                return JSON.stringify(j);
            },
            parse: function (jsonStr) {
                const j = JSON.parse(jsonStr);
                const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
                if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
                if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
                return cipherParams;
            }
        };
        
        let ddd = str
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(ddd), this.encryptKey);
        const bytes = CryptoJS.AES.decrypt(ddd, this.encryptKey);
        var plaintext = bytes.toString(CryptoJS.enc.Utf8);
    }

    encryptSquareToken(d){
        // console.log(d);
        const nonceString = d.nonce + '&&&&' + d.zipcode;
        console.log(nonceString);
		const CryptoJS = this.winRef.window.CryptoJS;
        const CryptoJSAesJson = {
            stringify: function (cipherParams) {
                const j = {
                	ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
                	iv: '',
                	s: ''
                };
                if (cipherParams.iv) j.iv = cipherParams.iv.toString();
                if (cipherParams.salt) j.s = cipherParams.salt.toString();
                return JSON.stringify(j);
            },
            parse: function (jsonStr) {
                const j = JSON.parse(jsonStr);
                const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
                if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
                if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
                return cipherParams;
            }
        };
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(nonceString), this.encryptKey, { format: CryptoJSAesJson }).toString();
        return encrypted;
    }
}
