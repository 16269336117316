import { Component, OnInit } from '@angular/core';
import { StaticpagesService } from '../../../services/staticpages.service';
import { Observable } from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Component({
  selector: 'app-monthly-subscription-agreement',
  templateUrl: './monthly-subscription-agreement.component.html',
  styleUrls: ['./monthly-subscription-agreement.component.scss']
})
export class MonthlySubscriptionAgreementComponent implements OnInit {

  page: String = 'open-subscription-agreement-monthly';
  staticPageContent: any = {};
  isLoadeds = false;
  constructor( private staticObj:StaticpagesService,private router: Router ) {
  }

  ngOnInit() {
    this.isLoadeds=false;

    this.staticObj.getCmsPageContent(this.page).subscribe((data:any)=> {
      this.staticPageContent = data.result[0];
      this.isLoadeds = true;
    }, (error) => { console.log('Error: ' + JSON.stringify(error));
      this.isLoadeds = true;
      }
  ); 
  }

}
