import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { CustomCode } from '../shared/CustomCode';

@Injectable()
export class AuthGuardGuard implements CanActivate {

  private checkLocalStorage = CustomCode.getLocalStorageData('access_token');

  constructor(private routerObj: Router) { }

  canActivate() {
    // alert(this.checkLocalStorage);
    if (this.checkLocalStorage == null) {
      this.routerObj.navigate(['/']);
    } else {
      return true;
    }
  }
}
