<app-headeragrigator></app-headeragrigator>
<div *ngIf="isLoaded==false" id="aggregator-loader-wrapper">
    <app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
</div>
<form *ngIf="isLoaded" (ngSubmit)="f.form.valid && monthlyPayment(formData, f,1)" #f="ngForm" novalidate method="POST" role="form" id="floating-label-buy-monthly" class="floating-label">

<div class="monthlyParking mar-bottom-lg">
    <div *ngIf="isLoyaltyMember" class="hidden-xs 1" style="margin-top:60px"></div>
    <div *ngIf="!isLoyaltyMember" class="hidden-xs 2" style="margin-top:50px"></div>
    <div *ngIf="isLoyaltyMember" class="visible-xs 3" style="margin-top:50px"></div>
    <div *ngIf="!isLoyaltyMember" class="visible-xs 4" style="margin-top:25px"></div>
    <div class="back-btn-top mar-bottom-0">
    <div class="container">

        </div>
       </div>
       <div class="container">
        
        <div class="row">
            <div class="col-sm-8 col-sm-offset-2 col-md-8 col-lg-6 col-md-offset-3">

                
              <div class="white-box-sdw srink-box">
                <h2 sourceSense style="font-size: 26px;">Thank you for your reservation!</h2>
                <p sourceSense style="font-size: 14px;">See your reservation details below.</p>
              </div>
              <div class="white-box-sdw srink-box mar-top-sm">
                <!-- <app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load> -->
                <div class="row" *ngIf="isLoaded==true">

                    <div class="col-md-6">
                        <h3 style="margin-bottom: 5px;" class="mar-top-0 mar-bottom-xs font-weight-bold"> {{selectedFacilityDetails?.facility_name_modified}}</h3>
                        <h4 class="mar-top-0 top-text-details-2 mar-bottom-xs">{{selectedFacilityDetails?.geolocations?.address_1}}</h4>
                        <div class="vissible-xs" style="padding-bottom: 10px;"></div>
                        <p style="margin-bottom: 0px;"><b style="font-size: 14px;">Between:</b> {{selectedFacilityDetails?.between_streets}}</p>
                        <p><b style="font-size: 14px;">Entrance:</b> {{selectedFacilityDetails?.entrance_location}}</p>
                        <div class="visible-xs" style="padding-bottom: 12px;"></div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-xs-6 col-md-12">
                      <p style="margin-bottom: 0px; font-weight: 800; font-size: 13px;">Monthly Parking Starting:</p>
                      <p class="text-primary mar-bottom-0"><b sourceFont style="font-size: 21px;">{{formatedDate}}</b></p>
                      <div class="hidden-xs" style="padding-bottom: 16px;"></div>
                      </div>
                      <div class="col-xs-6 col-md-12">
                      <p style="margin-bottom: 0px; font-weight: 800; font-size: 13px;">Monthly Rate:</p>
                      <p class="text-primary mar-bottom-0"><b sourceFont style="font-size: 21px;">${{pricePaid | number}}</b></p>
                    </div>
                    </div>
                    </div>
                </div>
              </div>
                <div class="white-box-sdw srink-box mar-top-sm">
                
                


                <h4 class="mar-top-xs mar-bottom-xs ">VEHICLE INFORMATION</h4>

                            <div class="row payment-login-info">

                                <div class="col-sm-6 col-md-5 col-lg-5">

                                    <div class="form-group mar-bottom-xs mar-top-xs">

                                        <div class="form-group mar-top-0 mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !make_vehicle.valid}">

                                        <select (change)="vehicleDetails(make_vehicle.value)"   name="make_vehicle"   [(ngModel)]="formData.make_vehicle" required #make_vehicle="ngModel" class="form-control" id="exampleFormControlSelect1">

                                          <option value=''>Vehicle Make</option>

                                          <option *ngFor="let makes of make; index as z" value='{{z}}'>{{makes.name}}</option>

                                         

                                        </select>

                                        <div *ngIf="f.submitted && !make_vehicle.valid" class="help-block">Vehicle Make is required</div>

                                    </div> 

                                    </div>

                                </div>

    

                                <div class="col-sm-6 col-md-5 col-lg-5">

                                    <div class="form-group mar-bottom-xs mar-top-xs">

                                    <div class="form-group mar-top-0 mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !make_modal.valid}">

                                        <select (change)="vehicleDetailsWithModal(make_vehicle.value,make_modal.value)"   name="make_modal"   [(ngModel)]="formData.make_modal" required #make_modal="ngModel" class="form-control" id="exampleFormControlSelect1">

                                          <option value=''>Vehicle Model</option>

                                          <option *ngFor="let makesModals of modalName; index as m" value='{{m}}'>{{makesModals.name}}</option>

                                        </select>

                                        <div *ngIf="f.submitted && !make_modal.valid" class="help-block">Vehicle Modal is required</div>

                                        </div>

                                    </div>

                                </div>
                               <div class="clearfix"></div>
                                <div class="col-sm-6 col-md-5 col-lg-5">

                                    <div class="form-group mar-bottom-xs mar-top-xs" [ngClass]="{ 'has-error' : f.submitted && !licenceplate.valid}">

                                        <input class="form-control" type="text" name="licenceplate" [(ngModel)]="formData.licenceplate" #licenceplate="ngModel" (keypress)="licenceplateFormat($event)" maxlength="15" placeholder="License Plate (optional)"/>
                                        <!-- <div *ngIf="f.submitted && !licenceplate.valid" class="help-block">License Plate is required</div> -->
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-5 col-lg-5">

                                    <div class="form-group mar-bottom-xs mar-top-xs" [ngClass]="{ 'has-error' : f.submitted && !vehiclecolor.valid}">

                                        <input class="form-control" type="text" name="vehiclecolor" [(ngModel)]="formData.vehiclecolor" #vehiclecolor="ngModel" required placeholder="Vehicle Color" />

                                        <div *ngIf="f.submitted && !vehiclecolor.valid" class="help-block">Vehicle Color is required</div>

                                    </div>
                                    <p class="smallTextAdded help-block eroor-help visible-xs mar-bottom-0" style="font-size: 11px;">{{dontahowmessge}}</p>

                                </div>
                                <div *ngIf="dontahowmessge" class="col-sm-12">
                                    <p class="smallTextAdded help-block eroor-help hidden-xs mar-bottom-0" style="font-size: 11px;">{{dontahowmessge}}</p>
                                </div>
                            </div>

                            <div class="vehicle-info-text mar-top-sm mar-bottom-sm" *ngIf="extraCharge">
                                <p><img src="/assets/img/icon-alert.png" width="16"/><b>{{heading}}</b> {{des_surchage}}</p>
                                <p class="text-danger">{{price_surcharge}}</p>
                                <div class="form-check newcheckbox-d">
                                    <input (change)="surcharge(surcharge_amount.value)" class="form-check-input" type="checkbox" name="surcharge_amount"  [(ngModel)]="formData.surcharge_amount" #surcharge_amount="ngModel" >
                                    <label class="form-check-label mar-left-sm" for="">
                                    I understand and accept additional surcharge fees. </label>
                                    <div *ngIf="f.submitted && !surcharge_amount.value" style="padding-top: 20px;" class="help-block eroor-help">**Please accept Surcharge fee Terms and conditions</div>
                                </div>
                            </div>

            </div>

           <div *ngIf="!isLogin" class="white-box-sdw your-purchage-box loginButtonBox  mar-top-sm srink-box">
                <p>
              <a href="javascript:;" (click)="redirectToSignUp();" data-toggle="modal" href='#login' class="btn btn-outline-primary ">LOG IN</a> &nbsp; <strong> OR CREATE ACCOUNT BELOW</strong></p>
               <p class="text-danger"><strong>*You must log in to or create a new account to proceed.</strong></p>
            </div> 
            <!-- <div *ngIf="isLogin" class="white-box-sdw your-purchage-box loginButtonBox  mar-top-sm srink-box">
                    <p class="text-danger"><strong>*If want to reservation from other user Email. </strong><a href="javascript:;" (click)="logoutUser();" >LOG OUT</a></p>
            </div>  -->
               <div class="white-box-sdw your-purchage-box  mar-top-sm srink-box">
                   <h4 class="mar-top-sx mar-bottom-xs ">ACCOUNT INFORMATION</h4>
                <div class="mar-top-sx payment-login-info" id="accountExpand" [collapse]="isCollapsed">

                    

                        <div class="row">

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !firstname.valid}">

                                    <input class="form-control" type="text" placeholder="First Name" id="firstname" name="firstname" [(ngModel)]="formData.firstname" #firstname="ngModel" required />

                                    <div *ngIf="f.submitted && !firstname.valid" class="help-block">First name is required</div>

                                </div>

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !lastname.valid}">

                                    <input class="form-control" type="text" placeholder="Last Name" name="lastname" [(ngModel)]="formData.lastname" #lastname="ngModel" required />

                                    <div *ngIf="f.submitted && !lastname.valid" class="help-block">Last name is required</div>

                                </div>

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !email.valid}">

                                    <input [readonly]='userLogin' class="form-control" type="text" placeholder="Email" name="email" [(ngModel)]="formData.email" #email="ngModel" required (keydown)="restSpace($event)" pattern="{{emailRegex}}" />

                                    <div *ngIf="f.submitted && !email.valid" class="help-block">Email is required</div>

                                </div>

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !phone.valid }">

                                    <input class="form-control" type="text" placeholder="Phone Number" name="phone" minlength="10" [textMask]="{mask: phoneMask}"  (keyup)="checkPhoneLength(formData.phone)" (keypress)="phoneFormat($event)" [(ngModel)]="formData.phone" #phone="ngModel" required />
                                    
                                    <!-- <div *ngIf="!validPhone" class="help-block error-msg">{{phoneLengthErrMsg}}</div> -->
                                    <div *ngIf="f.submitted && !phone.valid" class="help-block">Phone Number is required</div>
                                    <!-- <div *ngIf="f.submitted && phoneLengthErrMsg" class="help-block">{{phoneLengthErrMsg}}</div> -->

                                </div>

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0">

                                    <input class="form-control" type="text" placeholder="Company Name (optional)" name="companyname" [(ngModel)]="formData.companyname" #companyname="ngModel" />

                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !address1.valid}">

                                    <input class="form-control" type="text" placeholder="Address Line 1" name="address1" [(ngModel)]="formData.address1" #address1="ngModel" required />

                                    <div *ngIf="f.submitted && !address1.valid" class="help-block">Address Line 1 is required</div>

                                </div>

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0">

                                    <input class="form-control" type="text" placeholder="Address Line 2 (optional)" name="address2" [(ngModel)]="formData.address2" #address2="ngModel" />

                                </div>

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !city.valid}">

                                    <input class="form-control" type="text" placeholder="City" name="city" [(ngModel)]="formData.city" #city="ngModel" required />

                                    <div *ngIf="f.submitted && !city.valid" class="help-block">City is required</div>

                                </div>

                            </div>

                            <div class="col-sm-3 col-xs-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !state.valid}">

                                    <input class="form-control" type="text" placeholder="State" name="state" (keydown)="restDigit($event)" maxlength="2" [(ngModel)]="formData.state" #state="ngModel" required>

                                    <div *ngIf="f.submitted && !state.valid" class="help-block">State is required</div>

                                </div>

                            </div>

                            <div class="col-sm-3 col-xs-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !zipcode.valid}">

                                    <input class="form-control" maxlength="6" minlength="5" type="text" placeholder="Zip Code" name="zipcode" [(ngModel)]="formData.zipcode" #zipcode="ngModel" required />

                                    <div *ngIf="f.submitted && !zipcode.valid" class="help-block">Zip Code is required</div>

                                </div>

                            </div>

                        </div>

                        <div *ngIf="!isLogin" class=" mar-top-sm ">
                            <p class="font-weight-bolder mar-top-xs">Create Account Password</p>
                                <div  class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !password.valid}">
                                        <input class="form-control" type="password" name="password" placeholder="Create Password" (keyup)="checkPasswordLength(formData.password)" [(ngModel)]="formData.password" #password="ngModel" required="!isLogin"  validateEqual="cpassword" reverse="true"/>
                                        <div *ngIf="f.submitted && !password.valid" class="help-block">{{passwordErrMsg}}</div>
                                        <div *ngIf="passwordLengthErrMsg" class="help-block error-msg">{{passwordLengthErrMsg}}</div>
                                    </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !cpassword.valid}">
                                            <input class="form-control" type="password" placeholder="Confirm Password" name="cpassword" (keyup)="checkConfirmPassword(formData.cpassword)" [(ngModel)]="formData.cpassword" #cpassword="ngModel" required="!isLogin" validateEqual="password" />
                                            <div *ngIf="cPasswordErrMsg" class="error-msg help-block">{{cPasswordErrMsg}}</div>
                                            <div *ngIf="f.submitted && !cpassword.valid && cPasswordReqMsg" class="help-block">Confirm Password is required</div>
                                          </div>
                                    </div>
                                </div>
                        </div>

                        <!-- <h4 class="mar-top-xs text-uppercase">ADDITIONAL INFORMATION</h4> -->

                        <p class="font-weight-bolder mar-top-xs">The persons listed below are authorized to use this vehicle  &nbsp;

                            <a type="button"

                           popover="This vehicle will not be released to

                           any other individual without a

                           letter of authorization signed by

                           the monthly applicant."

                           placement="right" style="cursor:pointer;"><img [src]="helpIcon" width="16" alt="helpIcon"/></a>

                       </p>

                        <div class="row">

                            <div class="col-sm-6">

                                <div class="form-group">

                                    <input class="form-control" type="text" placeholder="Additional Driver 1 (optional)" name="driver1" [(ngModel)]="formData.driver1" #driver1="ngModel" />

                                </div>

                                <div class="form-group">

                                    <input class="form-control" type="text" placeholder="Additional Driver 2 (optional)" name="driver2" [(ngModel)]="formData.driver2" #driver2="ngModel" />

                                </div>

                                <div class="form-group">

                                    <input class="form-control" type="text" placeholder="Additional Driver 3 (optional)" name="driver3" [(ngModel)]="formData.driver3" #driver3="ngModel" />
</div>
                        </div>
                    </div>
                    <!-- <div class="additional-driver visible-xs">
                        <div id="accordion" class="accordion">
                            <div class="mar-top-xs mar-bottom-xs">
                                <div class="c-header collapsed" data-toggle="collapse" href="#driver1">
                                    <a class="card-title text-dark"><b>Add Additional Drivers</b><br>
                                    <span>Add up to 3 Drivers. This step is optional</span>
                                    </a>
                                </div>
                                <div id="driver1" class="collapse max-bottom-xs" data-parent="#accordion">
                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Additional Driver 1 (optional)" name="driver1" [(ngModel)]="formData.driver1" #driver1="ngModel" />
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Additional Driver 2 (optional)" name="driver2" [(ngModel)]="formData.driver2" #driver2="ngModel" />
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Additional Driver 3 (optional)" name="driver3" [(ngModel)]="formData.driver3" #driver3="ngModel" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div> -->
                    </div>
               
            </div>
                <div class="white-box-sdw your-purchage-box mar-top-sm srink-box">

                    <div class="mar-top-xs" id="accountExpand">

                      <h4 class="mar-top-0 text-uppercase font-weight-bolder">SET UP AUTO PAY</h4>
                      <p>For your convenience and quick service, would you like to set up AutoPay?<br>
                        This will enable us to continue serving you without any interruptions.</p>
                      <div class="form-check mt-2 newcheckbox-d">
                        <span>
                                <input id="setupaautopay" (change)="enableAutoPay = !enableAutoPay; chengeAutopayFormHandler(enableAutoPay)" [checked]="enableAutoPay" #checkAutoPay class="form-check-input" type="checkbox" name="autopay" required>
            
                                <label class="form-check-label mar-left-sm mar-bottom-xs mar-top-0" for="setupaautopay">
                                  <b style="padding-top: 6px;display: inline-block;" >Yes, set up my AutoPay</b></label>
                            </span>
                            </div>
                       <div [ngClass]="{'hide' : !enableAutoPay && !surchargeValue}">
                           <hr class="my-4" />
                        <h4 class="mar-top-0" style="padding-top: 5px;">Payment Details</h4>
                        <div class="red-box-payment" *ngIf='paymentError'>
                            <table class="hidden-xs">
                                <tr><td><img [src]="redboxstrip"></td>
                                <td>
                                <p><b>We were unable to authorize the credit card you provided.</b></p>
                                <p>Please confirm the card info or use another card and try again.</p>
                                </td></tr>
                            </table>
                            <p class="text-center visible-xs">
                              <b>We were unable to authorize the credit card you provided. Please confirm the card info or use another card and try again.</b>
                            </p>
                        </div>
                       <div id="monthpayment" class="collapse payment-login-info in">
                        <div class="row">

                                <div class="col-sm-7 col-md-7">

                                    <div class="form-group nameOnCardDiv mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !name_on_card.valid}">

                                        <input id="nameOnCard" class="form-control inputBoxStyling" type="text" autocomplete="off" name="name_on_card" placeholder="Name on Card" [(ngModel)]="formData.name_on_card" #name_on_card="ngModel"  [required]="(enableAutoPay || surchargeValue)?true:false"  />

                                        <div *ngIf="f.submitted && !name_on_card.valid" class="help-block">Name on Card is required</div>

                                    </div>

                                </div>

                            </div>

                            <div class="row">

                                <div class="col-sm-7 col-md-7">

                                    <div class="form-group paymentDiv mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !card_number.valid}">

                                        <input type="tel" autocomplete="off" placeholder="Card Number" (keyup)="checkCard(card_number)" [textMask]="{mask: cardMask}" (keypress)="restrictNumeric($event)" class="form-control inputBoxStyling" name="card_number" id="cardNumber" [(ngModel)]="formData.card_number"  [required]="(enableAutoPay || surchargeValue)?true:false"

                                            #card_number="ngModel" (ngModelChange)="formData.card_number = unmask($event)"  />

                                            <img height="22px" class="visible-xs" style="height: 22px;margin-top: 8px;position: absolute;right: 10px;top: 0;" src="../../../assets/img/card-list.png">

                                        <div *ngIf="f.submitted && !card_number.valid" class="help-block">Card Number is required</div>

                                    </div>

                                </div>

                                <div class="col-sm-5 col-md-5 hidden-xs">

                                    <div class="form-group paymentDiv mar-bottom-xs " >

                                            <img height="22px" style="height: 22px;margin-top: 8px;" src="../../../assets/img/card-list.png">

                                    </div>

                                </div>

                            </div>

                            <div class="row">

                                <div class="col-sm-7 col-md-7">

                                    <div class="form-group paymentDiv mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !expiration_date.valid}">

                                        <input type="tel" autocomplete="off" placeholder="Expiration Date (mm/yy)" (keyup)="setToexpirationField(expiration_date)" id="standardCardExpiration" [textMask]="{mask: dateMask}" minlength="4" class="form-control inputBoxStyling" name="expiration_date"  [required]="(enableAutoPay || surchargeValue)?true:false"

                                            [(ngModel)]="formData.expiration_date" #expiration_date="ngModel" (ngModelChange)="formData.expiration_date = unmask($event)"  />

                                        <input class="form-control" type="hidden" autocomplete="off" [(ngModel)]="formData.exp1" name="exp1" #exp1="ngModel" id="standardCardExpiration1">

                                        <div *ngIf="f.submitted && !expiration_date.valid" class="help-block">Expiration Date is required</div>

                                    </div>

                                </div>

                            </div>

                            <div class="row">

                                <div class="col-sm-7 col-md-7">

                                    <div class="form-group paymentDiv mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !security_code.valid}">

                                        <div class="input-group-1">

                                            <input type="tel" autocomplete="off" placeholder="CVV / Security Code" maxlength="4" class="form-control inputBoxStyling securityCodeInput" name="security_code" id="standardCardCvv" [(ngModel)]="formData.security_code" #security_code="ngModel" [required]="(enableAutoPay || surchargeValue)?true:false"

                                            />

                                            <div class="input-group-append ml-2 d-none d-sm-block my-2" container="body" containerClass="PaymentCustome" placement="top" [tooltip]="popTemplate"><img [src]="infoIcon" width="19" alt="i"></div>

                                            <!-- fix for mobile device tooltip -->

                                            <div class="input-group-append ml-2 d-block d-sm-none my-2" container="body" containerClass="PaymentCustome" placement="left" [tooltip]="popTemplate"><img [src]="infoIcon" width="19" alt="i"></div>

                                            <!-- tooltip fif ended -->

                                        </div>

                                        <div *ngIf="f.submitted && !security_code.valid" class="help-block zip-error">Security Code is required</div>

                                    </div>

                                </div>

                            </div>
                            <div class="row">

                                <div class="col-sm-7 col-md-7">

                                    <div class="form-group nameOnCardDiv mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !pzipcode.valid}">

                                        
                                        <input class="form-control" maxlength="6" minlength="5" type="text" name="pzipcode" placeholder="BIlling Address Zip Code" [(ngModel)]="formData.pzipcode" #pzipcode="ngModel" [required]="(enableAutoPay || surchargeValue)?true:false" />
                                        <div *ngIf="f.submitted && !pzipcode.valid" class="help-block">Zip Code is required</div>

                                    </div>

                                </div>

                            </div>
                            <!-- <div class="row">
                              <div class="col-sm-7 col-md-7">
                                  <div class="form-group nameOnCardDiv mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !pzipcode.valid}">
                                     <a style="cursor: pointer;" class="btn btn-outline-primary">SET UP AUTOPAY PAYMENT</a>
                                  </div>
                              </div>
                          </div> -->

                            </div>

                        </div>     

                        <div class="row mar-top-xs">

                        </div>

                    

                        <ng-template #template>

                            <div class="modal-header" style="margin-top: -10px;">

                                <h4 class="modal-title pull-left text-primary text-uppercase"></h4>

                                <button type="button" class="close pull-right" aria-label="Close" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" (click)="modalRef.hide(); enableButton()">

                                    <span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>

                                </button>

                            </div>

                            <div class="modal-body" style="padding: 10px 20px">

                                <h3 class="text-primary mar-top-0 mar-bottom-xs">Promo vouchers will be emailed in separate email after purchase.</h3>

                                <div class="btn-center-xs mar-top-sm mar-bottom-xs">

                                    <a class="btn btn-primary theme-btn" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" href="javascript:;" (click)="UpdatePromocodeStatus(formData, f)">Got it</a>

                                    <a class="btn btn-primary theme-btn" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" href="javascript:;" (click)="modalRef.hide(); enableButton()">Cancel</a>

                                </div>

                                <div class="mar-bottom-sm visible-xs"></div>

                            </div>

                        </ng-template>

                </div>

            </div>

            <div class="white-box-sdw your-purchage-box mar-top-sm srink-box newcheckbox-d">

                <div class="form-check mt-2"  id="testID">
            <span id="ModalovernightPopup" #myDiv (click)="lgModalovernightPopup.show();openpopupsetup()">
                    <input id="termsConditionCheck" class="form-check-input" type="checkbox" name="terms" [(ngModel)]="formData.terms" #terms="ngModel" required>

                    <label class="form-check-label mar-left-sm mar-bottom-xs mar-top-0" for="termsConditionCheck">
                        {{getUpdatedPrce(pricePaid)}}
                        <!-- <p [innerHtml]="termsConditionText"></p> -->
                       <!-- I understand that payment for the amount of <strong sourceFont style="font-weight:800;">${{getUpdatedPrce(pricePaid)}}</strong> will be charged today and then on the 1st of each month, as applicable to the term selected, to the credit card entered. 
                        The listed rate is guaranteed for 1 Month.  After the selected time frame, billing will continue at a rate that is subject to change until a cancellation request is submitted online.  
                            I understand and agree with the terms of this subscription. -->
                            <!-- <a type="button" style="cursor: pointer;" > Read Terms & Conditions</a> -->
                            I understand that payment for the amount of <strong sourceFont style="font-weight:800;">${{formatChange(checkFormatWithSurcharge(rateDetails))}}</strong> will be charged today and then on the 1st of each month, as applicable to the term selected, to the credit card entered. 
                            The listed rate is guaranteed for <!--{{selectedvahicletype | lowercase}}-->1 month. After the selected time frame, billing will continue at a rate that is subject to change until a cancellation request is submitted online. 
                            <!-- <span *ngIf="showtcextra">Early cancellation will be subject to 50% of your remaining subscription term balance, up to $1,000.</span> -->  I understand and agree with the terms of this subscription. <a type="button" style="cursor: pointer;" > Read Terms & Conditions</a> 
                    </label>
                </span>
                    <div *ngIf="f.submitted && !terms.valid" class="help-block eroor-help">Please accept Monthly Parking Terms and Conditions </div>

                </div>

            </div>
           

<div bsModal #lgModalovernightPopup="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" #bookerrormsgpopupclose class="close" aria-label="Close" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" (click)="lgModalovernightPopup.hide();closeTermsPopup()">
          <fa name="times"></fa>
        </button>
            </div>

            <div class="modal-body" style="padding-top:0px;">
                <!-- <div class="icon-rewards-terms mar-top-xs">
                    <div [innerHTML]="staticPageContent.post_content"></div>
                </div> -->
                <div class="scroll-container ps border" style="height:300px;overflow-y: scroll;" id="tcevnt">                
                    <div class="scrollable-content" [innerHTML]="staticPageContent.post_content"></div>
                </div>
                <div class="form-group my-4">
                    <span id="ModalovernightPopup" #myDiv (click)="lgModalovernightPopup.show()" >
                        <input (click)="activate_term_btn()" class="form-check-input " type="checkbox" name="terms_check" id="terms_checkbox" [disabled]="disabled"  required>
                        <label class="form-check-label ml-5 my-2" for="termsConditionCheck">By checking this box you have read, understood and agreed to the Terms & Conditions</label>
                    </span>
                </div>    
              <div class="form-group mar-top-sm mar-bottom-0 btn-center-xs">
                <!-- <button (click)="acceptTermsCondition();lgModalovernightPopup.hide();" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" type="button" class="btn btn-primary btn-fill">ACCEPT</button>
                <span class="mar-left-xs"></span>
                <button (click)="lgModalovernightPopup.hide();closeTermsPopup()" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" type="button" class="btn btn-primary theme-btn">CANCEL</button> -->
                <button (click)="acceptTermsCondition();lgModalovernightPopup.hide();" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" type="button" class="btn btn-primary btn-fill mb-2" [disabled]="disabled_btn" >ACCEPT</button>
                <span class="mar-left-xs"></span>
                <button (click)="lgModalovernightPopup.hide();closeTermsPopup()" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" type="button" class="btn btn-primary theme-btn mb-2">CANCEL</button>
                <span class="mar-left-xs"></span>
                <a routerLink="/monthly-terms-and-conditions/" target="_BLANK" style="white-space: break-spaces;" class="btn btn-link text-primary mb-2" >Open Icon Parking Monthly Agreement – Terms and Conditions</a>
            </div>
        </div>
    </div>
</div>
</div>

            <div class="d-block mar-top-sm">
                <!-- {{test(make_vehicle.valid , make_modal.valid , vehiclecolor.valid ,validPhone ,firstname.valid ,lastname.valid ,address1.valid ,city.valid ,state.valid , zipcode.valid ,name_on_card.valid ,card_number.valid , expiration_date.valid , security_code.valid ,pzipcode.valid ,terms.valid , validPwd ,validCPwd, disableSubmit)}} -->
                <button [disabled]="disableSubmit" #PromocodePopup type="button" style="min-width: 200px;" class="btn btn-primary btn-fill px-5 d-inline-block book-it 111 hide" (click)="openModal(template)">SUBMIT</button>
                
                <!-- <button *ngIf="!(isLogin) && (enableAutoPay|| surchargeValue)" [ngClass]="{'hide':isLogin}" [disabled]="disableSubmit" type="submit"  style="min-width: 200px;" class="btn btn-primary btn-fill px-5 d-inline-block book-it 222">SUBMIT</button> -->
                
                <!-- <button *ngIf="!(isLogin) && (!enableAutoPay && !surchargeValue)" [ngClass]="{'hide':isLogin}" [disabled]="disableSubmit" type="submit"  style="min-width: 200px;" class="btn btn-primary btn-fill px-5 d-inline-block book-it 333">SUBMIT</button> -->

                <!-- <button *ngIf="isLogin && (enableAutoPay || surchargeValue)"  [disabled]="disableSubmit " type="submit"  style="min-width: 200px;" class="btn btn-primary btn-fill px-5 d-inline-block book-it 444">SUBMIT</button> -->
                <button [disabled]="disableSubmit" type="submit"  style="min-width: 200px;" class="btn btn-primary btn-fill px-5 d-inline-block book-it 555">SUBMIT</button>

            </div>
            

            </div>

        <!-- <ng-template #monthlyFacilityDate>

            <div class="modal-header">

                <h4 class="modal-title text-primary text-uppercase pull-left"></h4>

                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">

                        <span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>

                      </button>

            </div>

            <div class="modal-body" style="padding: 10px 20px">

                <h3 class="text-uppercase mar-top-0 mar-bottom-xs"> {{respdata?.display_name}}</h3>

                <h3>Reservation Summary</h3>

        <p>Starting 04/07/2020</p>

        <div class="">

            <table width="100%" class="monthly-price-section">

                

                    <tr>

                        <td><b>Price</b></td>

                        <td  align="right"><b>{{CheckForSoldOut(checkFormat(rateDetailsNetRate))}}</b></td>

                    </tr>

                    <tr>

                        <td><b>Tax</b></td>

                        <td  align="right"><b>${{checkFormat(taxForThis)}}</b></td>

                    </tr>

            </table>

            <hr class="my-3"/>

            <table width="100%">

            <tr>

                <td class="font-weight-bolder pb-2" style="font-size: 18px;">Total </td>

                <td align="right"><h2 style="font-size: 18px;" class="my-0 font-weight-bolder pb-4">{{CheckForSoldOut(checkFormat(rateDetails) )}}
                    <br /><span class="taxIncludeText">(Taxes Included)</span>
                </h2></td>

            </tr>

          </table>

            <div class="d-block">
                 <button [disabled]="disableSubmit || !(make_vehicle.valid && make_modal.valid && vehiclecolor.valid && phone.valid && address1.valid && city.valid && state.valid && zipcode.valid && name_on_card.valid && card_number.valid && expiration_date.valid && security_code.valid && pzipcode.valid && terms.valid )" #PromocodePopup type="button" class="btn btn-primary btn-fill book-it hide" (click)="openModal(template)">BOOK IT </button> 
            </div>

            <p class="py-2 w-100 text-center"><a role="button" font-12 class="text-dark mt-4 text-center" style="cursor: pointer;"><img width="15px" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png"> Our Best Deals Guaranteed </a></p>
        </div>

        <div class=" mar-top-sm">

            <div class="">

                <p><img width="200" src="/assets/img/iconpark-safe.png" ></p>

                <ul class="dotsUl">
                    <li><h4 class="mar-bottom-0 text-primary">Service</h4>
                    <p>Fully contactless mobile reservations, payments, check-in & check-out</p>
                    </li>
                    <li>
                    <h4 class="mar-bottom-0 text-primary"> Sanitize</h4>
                    <p>Designated Viking Pure® natural cleaning stations at every location</p>
                    </li>
                    <li>
                    <h4 class="mar-bottom-0 text-primary">Safety</h4>
                    <p> Trained team members with gloves & masks, all practicing social distancing</p>
                    </li>
                    </ul>

            </div>

        </div>

            </div>

        </ng-template> -->

        </div>
    </div>





</div>

</form>

<!-- app footer -->

<app-footer></app-footer>

<ng-template #popTemplate>

    <img [src]="cardSecurityIcon" alt="helpIcon">



</ng-template>

