import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { CustomCode } from '../shared/CustomCode';
import { FormValidationErrors } from '../shared/formValidationErrors';
import * as $ from 'jquery';
import { UserserviceService } from '../services/userservice.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-createaccount',
  templateUrl: './createaccount.component.html',
  styleUrls: ['./createaccount.component.scss'],
  providers: [UserserviceService]
})
export class CreateaccountComponent implements OnInit {

  formErrorsCont = FormValidationErrors.formErrorsContainer;
  emailRegex = CustomCode.emailRegex;
  phoneMask: any = CustomCode.phoneMask;
  formData: any = {};
  private respdata;
  private parseData;
  passwordErrMsg='Password is required'
  constructor(private routeObj: Router, private userServiceObj: UserserviceService, private toasterObj: ToastrService) { }
  @ViewChild('myDiv',{static:true}) myDiv: ElementRef;
  ngOnInit() {
    setTimeout(()=>{ 
      $("input[name='password']").parents().removeClass('focused');
      $("input[name='email']").parents().removeClass('focused');
      $("input[name='email']").val('');
      $("input[name='password']").val('');
    }, 500);
  }

  registerUser(formValue, formObj: NgForm) {
    const reg =/<(.|\n)*?>/g; 
    if (reg.test(formValue.name) == true) {
      this.toasterObj.error('HTML Tags are not allowed in Name.', 'Sorry!');
      return false;
    } else {
      if (reg.test(formValue.phone) == true) {
        this.toasterObj.error('HTML Tags are not allowed in Phone.', 'Sorry!');
        return false;
      } else{
        if (reg.test(formValue.password) == true) {
          this.toasterObj.error('HTML Tags are not allowed in Password.', 'Sorry!');
          return false;
        } else {
          if (reg.test(formValue.cpassword) == true) {
            this.toasterObj.error('HTML Tags are not allowed in confirm Password.', 'Sorry!');
            return false;
          }else{
            //return true;
          }
        }
      }
    }

    if(formValue.phone != undefined && formValue.phone != ''){
      let ph = formValue.phone.replace(/\D/g, '');
      if(ph.length !==0 && ph.length < 10){
        formObj.form.controls['phone'].setErrors({'invalid': true});
        return false;
      }
    }

    if (formValue.password === undefined || formValue.password === '') {
      this.passwordErrMsg = 'Password is required';
      formObj.form.controls['password'].setErrors({'invalid': true});
      return false;
    }else if(formValue.password.length < 8){
      this.passwordErrMsg = 'Password must be atleast 8 characters long';
      formObj.form.controls['password'].setErrors({'invalid': true});
      return false;
    }else if(formValue.password !== formValue.cpassword){
      formObj.form.controls['cpassword'].setErrors({'invalid': true});
      return false;
    }

    this.userServiceObj.registerUser(formValue)
      .subscribe(
      data => {
        this.respdata = data;
        if (this.respdata.status === 201) {
          formObj.resetForm();
          
          if (localStorage.getItem('is_landing_page')) {
            let action_type = localStorage.getItem('action_type');
            let routeUrl = localStorage.getItem('routeUrl');
            let promocode = localStorage.getItem('promocode');
            let promotion_id = localStorage.getItem('promotion_id');
            let template_slug = localStorage.getItem('template_slug');
            let landing_page_url = "/"+routeUrl+"/" + template_slug + "/" + promotion_id + "/" + promocode + "/" + action_type;
            this.routeObj.navigate([landing_page_url]);
          } else {
            /* #start login user after registration */
            const userVal = {
              user:{
                name:this.respdata.data.user.name, 
                id:this.respdata.data.user.id,
                email:this.respdata.data.user.email,
                phone:this.respdata.data.user.phone,
                is_loyalty:this.respdata.data.user.is_loyalty,
                is_loyalty_active:this.respdata.data.user.is_loyalty_active
              },
              access_token:this.respdata.data.session.access_token,
              refresh_token:this.respdata.data.session.refresh_token,
              expires_in:this.respdata.data.session.expires_in,
              token_type:this.respdata.data.session.token_type,
            }

            CustomCode.markLoginUser(userVal);
            let isFirstRegister = this.respdata.data.is_new_user;

            if(localStorage.getItem('frombuymonthly'))
            {
              let routeNavigate = '/aggregator-monthly-booking';
              let facId=localStorage.getItem('monthlyParkingIds')
              let fDate=localStorage.getItem('monthlyParkingDates')
              localStorage.removeItem('frombuymonthly')
              localStorage.removeItem('monthlyParkingDates')
              localStorage.removeItem('monthlyParkingIds');
              //console.log('1111111111111111111');
              this.routeObj.navigate([routeNavigate, facId, fDate]);
            }

            else if(isFirstRegister){
              this.routeObj.navigate(['/account-confirmation']);
              return false;
            }else{
              if(localStorage.getItem('is_loyalty_active')==="0"){
                this.routeObj.navigate(['/my-account/manage-icon-rewards-card']);
                return false;
              }
              this.routeObj.navigate(['/my-account/account']);
              return false;
            }
            /* #end login user after registration */
          }
        }
      },
      error => {
        /* FormValidationErrors.displayServerErrors('signuperrors-container', error.errors); */
        const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
        } else {
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
              }
            }
          }
        }
      }
      );
  }

  restChar(event: any){
    if(!CustomCode.restricSpecialChar(event)){
      event.preventDefault();
    }
  }

  onPaste(event: any){
    if(!CustomCode.restricPaste(event)){
      event.preventDefault();
    }
  }
  restSpace(event: any){
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }
  phoneFormat(event: any) {
    const pattern = /[0-9\)\-\(\.\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar) && event.keyCode != 9) {
      event.preventDefault();
    }
  }
}
