import { error } from 'util';
import { Injectable } from '@angular/core';

//import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import {Resolve,ActivatedRouteSnapshot,RouterStateSnapshot} from '@angular/router'
// Import RxJs required methods
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchpageserviceService {

  constructor(private http: HttpClient) { }
  private checkLatLongLocation = 'https://maps.googleapis.com/maps/api/geocode/json';

  private getFacilityUrl = CustomCode.APIURL + 'search/facilities-rates';
  private getNeighbourhoorsUrl = CustomCode.APIURL + 'neighborhood';


  private allFacilityApi = CustomCode.APIURL + 'facility';

  private getFacilityDetailsApi = CustomCode.APIURL + 'facility/';

  private getInvertoryDetailsApi = CustomCode.APIURL + 'rate-facility-on-side-map';

  private getFacilityCoupons = CustomCode.APIURL + 'rates/facility/type/';

  private mailCouponApi = CustomCode.APIURL + 'coupon/email';
  private mailCouponApiNew = CustomCode.APIURL + 'coupon/new_design/email';


  private mailLandingCouponApi = CustomCode.APIURL + 'coupon/landing/email';
  private mailLandingValineTineCouponApi = CustomCode.APIURL + 'coupon-valentine/landing/email';
  private mailLandingCouponApiLanding = CustomCode.APIURL + 'coupon/landing/new_design/email';

  private globalEmailLandingCouponApi = CustomCode.APIURL +'coupon/global/email';

  private globalEmailLandingCouponImageApi = CustomCode.APIURL +'coupon/global-image/email';

  private printCouponApi = CustomCode.APIURL + 'coupon/jpg/';
  private printValineTIneCouponApi = CustomCode.APIURL + 'coupon-valentine/jpg/';
  private printCouponApiDetails = CustomCode.APIURL + 'get-coupon/details/';

  private getMobileCouponCode = CustomCode.APIURL + 'get-coupon-mobile-code';

  private saveCouponApi = CustomCode.APIURL + 'user/';

  private bookingRateApi = CustomCode.APIURL + 'rate/facility/';
  private UpdateBookingRateApi = CustomCode.APIURL + 'rate/facility-on-marker/';
  private vehicleDetals = CustomCode.APIURL + 'get-facility-makes-models';
  private getMonthlyParkingData = CustomCode.APIURL + 'get-monthly-parking-data';
  private aggregatorVoucherValidationUrl = CustomCode.APIURL + 'aggregator-voucher';

  mailCoupon(formData, couponId) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'id': couponId, 'email': formData.ucmail};
    return this.http.post(this.mailCouponApi, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }
  mailCouponNew(formData, couponId) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'id': couponId, 'email': formData.ucmail};
    return this.http.post(this.mailCouponApiNew, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }
  // resolve(route:ActivatedRouteSnapshot,rstate:RouterStateSnapshot){
  //   const options = CustomCode.returnHeaders();

  //   const formDetails = {'latitude': '40.7313215', 'longitude': '-73.9901486', 'radius': 1,
  //   'length_of_stay': 2, 'arrival_time': '2018-08-16T11:33:18', 'verbose': 'true'};

  //   return this.http.post(this.getFacilityUrl, formDetails, options)
  //   .map((res: any) => res)
  //   .catch((error: any) => Observable.throw(error));
  // }
  mailLandingCoupon(formData, couponId) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'id': couponId, 'email': formData.ucmail};
    return this.http.post(this.mailLandingCouponApi, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }

  mailLandingValineTineCoupon(formData, couponId) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'id': couponId, 'email': formData.ucmail};
    return this.http.post(this.mailLandingValineTineCouponApi, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }

  

  mailLandingCouponLanding(formData, couponId) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'id': couponId, 'email': formData.ucmail};
    return this.http.post(this.mailLandingCouponApiLanding, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }
  globalEmailLandingCoupon(formData, couponId) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'id': couponId, 'email': formData.ucmail};
    return this.http.post(this.globalEmailLandingCouponApi, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }

  globalEmailLandingCouponImage(formData, couponImg) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'image': couponImg, 'email': formData.ucmail};
    return this.http.post(this.globalEmailLandingCouponImageApi, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }
  
  saveCoupon(userId, couponId) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'coupon_id': couponId};
    return this.http.post(this.saveCouponApi + '' + userId + '/coupon', formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }
  printCouponDetails(couponId) {
    const options = CustomCode.returnHeaders();
    return  this.http.get(this.printCouponApiDetails + '' + couponId,{headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }
  printCoupon(couponId) {
    const headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    
    headers.append('Authorization', 'Bearer ' + access_token);
    headers.append('X-Big-Apple',  bigApple);
    //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return new Promise((resolve, reject) => {
      this.http.get(this.printCouponApi + '' + couponId, { responseType: 'blob',headers: new HttpHeaders({'Authorization': 'Bearer ' + access_token,'X-Big-Apple':  bigApple})}).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  printCouponForValineTine(couponId) {
    const headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    
    headers.append('Authorization', 'Bearer ' + access_token);
    headers.append('X-Big-Apple',  bigApple);
    //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return new Promise((resolve, reject) => {
      this.http.get(this.printValineTIneCouponApi + '' + couponId, { responseType: 'blob',headers: new HttpHeaders({'Authorization': 'Bearer ' + access_token,'X-Big-Apple':  bigApple})}).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  
  getMobileCode() {
    const headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    headers.append('Authorization', 'Bearer ' + access_token);
    headers.append('X-Big-Apple',  bigApple);
    //const options = new RequestOptions({ headers: headers });
  
     return  this.http.get(this.getMobileCouponCode,{ responseType: 'blob',headers: new HttpHeaders({'Authorization': 'Bearer ' + access_token,'X-Big-Apple':  bigApple})}).map((res: any) => res)
  }
  fetchBookingRates(URI_STRING) {
    return new Promise((resolve, reject) => {
      this.http.get(this.bookingRateApi + URI_STRING).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  fetchUpdatedBookingRates(URI_STRING) {
    return new Promise((resolve, reject) => {
      this.http.get(this.UpdateBookingRateApi + URI_STRING).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  

  getLatLongLocation(lat, long) {
    return new Promise((resolve, reject) => {
      this.http.get(this.checkLatLongLocation + '?latlng=' + lat + ',' + long + '' + CustomCode.googleMapApiKey).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getFacility(lat, long, radius, length_of_stay, arrival_time) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'latitude': lat, 'longitude': long, 'radius': radius,
    'length_of_stay': length_of_stay, 'arrival_time': arrival_time, 'verbose': 'true'};

    return this.http.post(this.getFacilityUrl, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }

  getFacilityMonthlyCampaign(data) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'latitude': data.latitude, 'longitude': data.longitude, 'radius': data.radius,
    'length_of_stay': data.length_of_stay, 'arrival_time': data.arrival_time, 'verbose': 'true', 'monthly_campaign':data.monthly_campaign,'neighID':data.neighID,'opens24Hours':data.opens24Hours,'opensOnWeekends':data.opensOnWeekends,'opensOnWeekDays':data.opensOnWeekDays,'temporaryClosed':data.temporaryClosed};

    return this.http.post(this.getFacilityUrl, formDetails, {headers:options})
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }

  getNeighbourhoods() {
    return this.http.get(this.getNeighbourhoorsUrl)
    .map((res: any) => res)
    .catch((error: any) => Observable.throw(error));
  }



  getFacilityDetails(parkingId) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getFacilityDetailsApi + '' + parkingId).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getFacilitySchedule(parkingId) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getFacilityDetailsApi + '' + parkingId + '/hours').subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  getFacilityScheduleWorkingHours(parkingId) {
    return  this.http.get(this.getFacilityDetailsApi + '' + parkingId + '/hours');
  }
  
  getFacilityScheduleFormatted(parkingId) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getFacilityDetailsApi + '' + parkingId + '/hours-formatted').subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getAllFacilities() {
    return new Promise((resolve, reject) => {
      this.http.get(this.allFacilityApi).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getFacilityTypeCoupon(facTypeId) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getFacilityCoupons + '' + facTypeId).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getInventoryUpdatedForSidebar(querystring) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getInvertoryDetailsApi + '?' + querystring).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  getVehicleDetails(fcd)
  {
    return new Promise((resolve, reject) => {
      this.http.get(this.vehicleDetals + '/' + fcd).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getMonthlyParkingReservationDetails(redId){
    return this.http.get( this.getMonthlyParkingData+ '/'+redId )
    .map( (res:Response)=> res )
    .catch( (error:any)=> Observable.throw(error) )
  }

  // getAggrigatorVoucher(voucher){
  //   // console.log(voucher)
  //   return this.http.get( this.aggregatorVoucherValidationUrl+'/'+voucher ).map(
  //     res => res 
  //   ).catch( (error:any)=> Observable.throw(error) )
  // }

  checkAggrigatorVoucher(voucher)
  {
    return new Promise((resolve, reject) => {
      this.http.get(this.aggregatorVoucherValidationUrl + '/' + voucher).subscribe(
        (res:any) => {
          resolve(res);
        },
        (error:any) => {
          reject(error.error);
        }
      );
    });
  }

}