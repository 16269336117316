// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://staging7-api.iconparkingsystems.com/',
  // baseUrl: 'https://staging7.iconparkingsystems.com/',
  // imageUrl: 'https://staging7.iconparkingsystems.com/',
  // adminUrl:'https://admin-staging7.iconparkingsystems.com/admin/user',
  // adminUrlLogin:'https://admin-staging7.iconparkingsystems.com',
  // cmsUrl:'https://staging7.iconparkingsystems.com/'


  
   apiUrl: 'https://staging4-api.iconparkingsystems.com/',
   baseUrl: 'https://redeemmonthly-staging2.iconparkingsystems.com/',
   imageUrl: 'https://redeemmonthly-staging2.iconparkingsystems.com/',
   adminUrl:'https://admin-staging2.iconparkingsystems.com/admin/user',
   adminUrlLogin:'https://admin-staging2.iconparkingsystems.com',
   cmsUrl:'https://staging2.iconparkingsystems.com/'  


  // apiUrl: 'https://test-api.iconparkingsystems.com/',
  // baseUrl: 'https://test.iconparkingsystems.com/',
  // imageUrl: 'https://test.iconparkingsystems.com/',
  // adminUrl:'https://admin-test.iconparkingsystems.com/admin/user',
  // adminUrlLogin:'https://admin-test.iconparkingsystems.com',
  // cmsUrl:'https://test.iconparkingsystems.com/'
  
//  apiUrl: 'https://staging-api.iconparkingsystems.com/',
//  baseUrl: 'https://staging.iconparkingsystems.com/',
//  imageUrl: 'https://staging.iconparkingsystems.com/',
//  adminUrl:'https://admin-staging.iconparkingsystems.com/admin/user',
//  adminUrlLogin:'https://admin-staging.iconparkingsystems.com',
//  cmsUrl:'https://staging.iconparkingsystems.com/'

};
